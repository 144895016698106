import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function ScorecardFooterNav({toggleSummary,onSettingsClick,isComplete,eventId}) {
  return (
    <Container>
        <Row>
            <Col className='mx-0 px-0 text-center'>
                {isComplete 
                    ? 
                        <Button style={{background:'#BFBFBF',color:"#2D083F",border:"0",fontWeight:"bold"}}> 
                            Settings
                            ⚙️
                        </Button>
                    : 
                        <Button style={{background:'#4D81F4',border:"0",fontWeight:"bold"}} onClick={onSettingsClick}>
                            Settings
                            ⚙️
                        </Button>
                }
            </Col>
            <Col className='mx-0 px-0 text-center'>
                <Link to={'/event?event='+eventId}>
                    <Button style={{background:'#4D81F4',border:"0",fontWeight:"bold"}}>    
                        Event Home
                    </Button>
                </Link>
            </Col>
            <Col className='mx-0 px-0 text-center'>
                <Button onClick={toggleSummary} style={{background:'#4D81F4',border:"0",fontWeight:"bold"}}>
                    {/*  className='float-right' */}
                    Summary
                </Button>
            </Col>
        </Row>
    </Container>
  )
}

export default ScorecardFooterNav