import React from 'react';

function AdminPagePaymentsCopyChargeButton (charge) {
    const [isCopied, setIsCopied] = React.useState(false);

    return (
        <>
            {charge.charge.substring(0, 11)}...
            {isCopied && <button className="btn btn-sm btn-secondary"> Copied!</button>}
            {!isCopied && (
            <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                    setIsCopied(true);
                    navigator.clipboard.writeText(charge.charge)
                }}
            >
                Copy
            </button>
            )}
        </>
    );
};

export default AdminPagePaymentsCopyChargeButton;