import React from 'react';
import { useForumPosts } from '../util/db';
import EventChatDisplayPostsRow from './EventChatDisplayPostsRow';
import {  Table } from 'react-bootstrap';

function EventChatDisplayPostsTable({eventId}){
//get posts from db hook
    const {
        data: items,
        // status: itemsStatus,
        // error: itemsError,
    } = useForumPosts(eventId);

    return (
        
        <Table responsive className='table table-striped'>
            <thead style={{background:"#2D083F"}}>
                <tr>
                    <th className='text-center'>
                            <p style={{color:'#ffffff'}}>
                                User
                            </p>
                    </th>

                    <th className='text-center'>
                           <p style={{color:'#ffffff'}}>
                                Date/Time
                            </p>
                    </th>
     
                    <th className='text-center'>
                            <p style={{color:'#ffffff'}}>
                                Post
                            </p>
                    </th>
                    <th className='text-center'>
                            <p style={{color:'#ffffff'}}>
                                Hide
                            </p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {items && items.length > 0 && (
                    items.map((post, index) => (
                    <EventChatDisplayPostsRow key={index} post={post} />
                    ))
                )}
            </tbody>
        </Table>
        
    )
}
export default EventChatDisplayPostsTable;