import React from 'react'
import EventCreateChooseDivisionCheckbox from './EventCreateChooseDivisionCheckbox'

function EventCreateChooseDivisions({divisions,selectedDivisions,setSelectedDivisions}){

    return (
        divisions.map((division, index) => (
            <EventCreateChooseDivisionCheckbox key={index} division={division} selectedDivisions={selectedDivisions} setSelectedDivisions={setSelectedDivisions} />
        ))

    )
}

export default EventCreateChooseDivisions