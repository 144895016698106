import React from 'react'
import { useAuth } from "../util/auth";
import { useState } from 'react';
import Meta from '../components/Meta';
import { useEffect } from 'react';
import { getIsTd } from '../util/db';
import DashboardItemsTd from '../components/DashboardItemsTd';

function EventViewPageLD(props) {

  const auth = useAuth();
  const uid = auth.user ? auth.user.uid : '';
  const [isLoggedIn,setIsLoggedIn] = useState(false)
  const [isPlayerTd, setIsPlayerTd] = useState(false);
  
  //if uid is not null, check if user is a TD
  const isTd = uid ? getIsTd(uid) : null;

  //when getistd promise is resolved, assign isplayertd to the result
  if(isTd !== null){
    isTd.then((result) => {
      setIsPlayerTd(result.is_td)
    })
  }

  useEffect(() => {
    if(uid !== ''){
        setIsLoggedIn(true)
    }else{
        setIsLoggedIn(false)
    }
  }, [uid])
 
  return (
    <>
    <Meta title='Create Event' />

      
      {/* if user is logged in and also a TD, display event creation tools */}
      {isLoggedIn && isPlayerTd && (
        <DashboardItemsTd auth={auth} />
      )}

    </>
  )
}

export default EventViewPageLD