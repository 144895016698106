import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormField from "./FormField";
import { createUserName } from "../util/db";
import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";

function PromptUsersNameForm(props){

    const [pending,setPending] = useState(null)

    // useEffect(() => {
    //   console.log('pending (ue) =', pending);
    // }, [pending])
    
    const handleSubmit = (event) => {
        event.preventDefault()
        setPending(true)
        // console.log(event.target.name.value)
        createUserName({name: event.target.name.value}, props.uid)
        setPending(false)
    }
    
    // const { register, errors, reset, getValues } = useForm();
    

    return (
      <Container>

      <Card className="mt-5">
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
        style={{background:"#2D083F", text:"white"}}
      >
        <Card.Title style={{color:"#ffffff"}}>
          Name
        </Card.Title>
            
      </Card.Header>
      <Card.Body  className="justify-content-between align-items-center" style={{background:"#8CABFF"}}>                
        <Card.Text>
          Please enter your full name
        </Card.Text>

        <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <FormField
            name="name"
            type="text"
            // label="Full Name"
            placeholder="Full Name"
            size="lg"
            // error={errors.name}
            // inputRef={register({
            //   required: "Please enter your Full Name",
            // })}
          />
        </Form.Group>
        {/* <Form.Group controlId="formUid">
          <FormField
            uid={props.uid}
            value={props.uid}
            type="text"
            hidden
            // label="Full Name"
            // placeholder="Full Name"
            size="lg"
            // error={errors.name}
            // inputRef={register({
            //   required: "Please enter your Full Name",
            // })}
          />
        </Form.Group> */}

        <Button type="submit" size="lg" disabled={pending}>
          {!pending ?  
          <span>Save</span>
          :
          <span>Saving</span>
          }
          

          {pending && (
            <Spinner
              animation="border"
              size="sm"
              role="status"
              aria-hidden={true}
              className="ml-2 align-baseline"
            >
              <span className="sr-only">Sending...</span>
            </Spinner>
          )}
        </Button>
      </Form>  
      </Card.Body>
    </Card>
    </Container>
    )
}

export default PromptUsersNameForm