import React from "react";
import Meta from "./../components/Meta";
// import HeroSection from "./../components/HeroSection";
// import FeaturesSection from "./../components/FeaturesSection";
// import ClientsSection from "./../components/ClientsSection";
// import TestimonialsSection from "./../components/TestimonialsSection";
// import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";
import EventsSection from './../components/EventsSection'
import HowToSection from "../components/HowToSection";
import { useAuth } from "./../util/auth";
import Auth from "../components/Auth";
import AuthFooter from "../components/AuthFooter";
import Container from "react-bootstrap/Container";

function IndexPage(props) {

  const auth = useAuth();
  const options = {
    title: "Sign in",
    buttonAction: "Sign in",
    showFooter: true,
    signupAction: "Create an account",
    signupPath: "/auth/signup",
    forgotPassAction: "Forgot Password?",
    forgotPassPath: "/auth/forgotpass"
  }

  return (
    <>
      <Meta />
      {!auth.user &&
        <>
          <Container
            style={{
              maxWidth: "450px",
              paddingTop: "2rem",
            }}
          >
            <Auth
              buttonAction={'Login'}
              afterAuthPath={'/'}
              type={'signin'}
              providers={["google"]}
            />
          <AuthFooter 
            type={'signin'} 
            options={options} 
            signupAction={"Create an account"} 
            signupPath={"/auth/signup"}
            forgotPassAction={"Forgot Password?"}
            forgotPassPath={"/auth/forgotpass"} 
            />
          </Container>
      {/*<hr style={{ width: '90%', backgroundColor:'#000000'}} />*/}
        </>
      }
      {/* <HeroSection
        bg="primary"
        textColor="light"
        size="lg"
        bgImage="https://source.unsplash.com/ugnrXk1129g/1600x800"
        bgImageOpacity={0.3}
        title="Your landing page title here"
        subtitle="This landing page is perfect for showing off your awesome product and driving people to sign up for a paid plan."
        buttonText="Get Started"
        buttonColor="light"
        buttonPath="/pricing"
      /> */}
      <EventsSection
        // bg="white"
        // textColor="dark"
        // size="md"
        // bgImage=""
        // bgImageOpacity={1}
        // title="Events"
        // subtitle="Disc Golf Events"
      />
      {/* <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      /> */}
      {/* <ClientsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="You're in good company"
        subtitle=""
      /> */}
      {/* <TestimonialsSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      /> */}
      {/* <NewsletterSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      /> */}

      

      <hr style={{ width: '90%', backgroundColor:'#000000'}} />
      
      <HowToSection />

      {!auth.user &&
        <CtaSection
          bg="primary"
          textColor="light"
          size="sm"
          bgImage=""
          bgImageOpacity={1}
          title="Is this your first time?"
          subtitle=""
          buttonText="Create an Account"
          buttonColor="light"
          buttonPath="/auth/signup"
        />
      }
    </>
  );
}

export default IndexPage;
