// button
import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
// updateeventdivisionlayout from db
import { updateEventDivisionLayout } from '../util/db';

function EventManageLayoutsChangeRow({division,layouts}) {

    const [isEditted, setIsEditted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [layout, setLayout] = useState(
        division.layout_id
    );

    function onSubmit(e){
        e.preventDefault();
        setIsSubmitting(true);
        updateEventDivisionLayout(division.event_divisions_id, layout)
        setIsSubmitted(true);
        setIsSubmitting(false);
    }

    function onLayoutChange(e){
        setLayout(e.target.value);
        setIsEditted(true);
        setIsSubmitted(false);
    }

    return (
        <tr>
            <td>{division.divisions.name}</td>
            <td>
                <Form.Control as="select" style={{color:"#2D083F"}} required value={layout} onChange={onLayoutChange}>
                    {layouts?.map((layout, index) => (
                        <option key={index} value={layout.layout_id}>{layout.layout_name}</option>
                    ))}
                </Form.Control>
            </td>
            <td>
                <Form onSubmit={onSubmit}>
                <Button type="submit" disabled={!isEditted || isSubmitting}>
                            {isSubmitted ? '✔' : isSubmitting ? <Spinner animation="border" /> : 'Update'}
                        </Button>
                    {/* <Button variant="primary">Update</Button> */}
                </Form>
            </td>
        </tr>
    );
}

export default EventManageLayoutsChangeRow;