import React from 'react'
import {  usePayments } from '../util/db'
import AdminPagePaymentsRow from './AdminPagePaymentsRow';

function AdminPagePayments() {
    
    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = usePayments();

    return (
        <table className="table">
            <thead>
                <tr>
                <th>Refund</th>
                    <th>Player</th>
                    <th>Event</th>
                    <th>Created At</th>
                    <th>Amount</th>
                    <th>Charge</th>
                    <th>Customer</th>
                    <th>Payment Intent</th>
                    <th>Currency</th>
                    <th>Payment Status</th>
                    <th>Amount Refunded</th>

                </tr>
            </thead>
            <tbody>
                {itemsStatus !== "loading" && items && items.length > 0 && (
                    items.map((item, index) => (
                        <AdminPagePaymentsRow key={index} item={item}/>
                    ))
                )}
            </tbody>
        </table>
    )
}

export default AdminPagePayments