import React from 'react';
import ScorecardScoreButton from './ScorecardScoreButton';

function ScorecardRowLeftScoreTd({onClickLockedScore, onClickLeftScore, isComplete, hole_scores_id}) {

    return (
        isComplete
            ?
                <td onClick={() => onClickLockedScore()}>
                    <ScorecardScoreButton backgroundColor={'#BFBFBF'} textColor={'#2D083F'} text={'-'} />
                </td>
            :
                hole_scores_id !== null 
                    ?
                        <td onClick={() => onClickLeftScore()}>
                            <ScorecardScoreButton backgroundColor={'#4D81F4'} textColor={'#d9e3ff'} text={'-'} />
                        </td>
                    :
                        <td onClick={() => onClickLeftScore()}>
                            <ScorecardScoreButton backgroundColor={'red'} textColor={'#d9e3ff'} text={'-'} />
                        </td>
    )

}

export default ScorecardRowLeftScoreTd;