import React from "react";
import FormAlert from "./FormAlert";
import "./DashboardItems.scss";
import { useEntriesByEventPlayerScorecard } from "../util/db";
import ScorecardBeginCheckIn from "./ScorecardBeginCheckIn";
import ScorecardEventCompleteCard from "./ScorecardEventCompleteCard";
import ScorecardBeginScorecardExists from "./ScorecardBeginScorecardExists";

function ScorecardBeginComponent(props) {
  const uid = props.userId ? props.userId : null;
  const eventId = props.eventId;
  const auth = props.auth;

  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useEntriesByEventPlayerScorecard(eventId, uid);

  const itemsAreEmpty = !items || items.length === 0;

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      {!itemsAreEmpty && (//a scorecard does exist for this player/event
          <ScorecardBeginScorecardExists items={items} itemsStatus={itemsStatus} eventId={eventId} auth={auth}/>
      )}
      {!props.isEventComplete 
        ? 
          <ScorecardBeginCheckIn isCheckedInAlready={props.isCheckedInAlready} isIncompleteCards={props.isIncompleteCards} eventId={props.eventId} canCheckIn={props.canCheckIn}/>
        :
          <ScorecardEventCompleteCard />
      }
      
    </>
  )
}

export default ScorecardBeginComponent;
