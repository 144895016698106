import React from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import EventCreateChoosePayoutMethodsRow from './EventCreateChoosePayoutMethodsRow';

function EventCreateChoosePayoutMethodsTable({selectedDivisions,setSelectedDivisions,payoutMethods}){

    return (
        <Table responsive className='table table-striped'>
            <thead style={{background:"#2D083F"}}>
                <tr>
                    <td className='text-center'>
                        <Form.Label style={{color:'#ffffff'}}>Division</Form.Label>
                    </td>
                    <td className='text-center'>
                        <Form.Label style={{color:'#ffffff'}}>Weekly Payout Method</Form.Label>
                    </td>
                    <td className='text-center'>
                        <Form.Label style={{color:'#ffffff'}}>Series Payout Method</Form.Label>
                    </td>
                </tr>
            </thead>
            <tbody>
                {selectedDivisions.map((selectedDivision, index) => (
                    <EventCreateChoosePayoutMethodsRow key={index} selectedDivision={selectedDivision} selectedDivisions={selectedDivisions} setSelectedDivisions={setSelectedDivisions} payoutMethods={payoutMethods} />
                ))}
            </tbody>
        </Table>
    )

}

export default EventCreateChoosePayoutMethodsTable;