import React from 'react';
import { useCoursesAdmin } from '../util/db';
import { Link } from 'react-router-dom';

function AdminPageDisplayCourses(){
    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = useCoursesAdmin()

    return (
        <table className="table">
            <thead>
                <tr>
                    <th>Course Name</th>
                    <th>Added By?</th>
                </tr>
            </thead>
            <tbody>
                {itemsStatus !== "loading" && items && items.length > 0 && (
                    items.map((item, index) => (
                    <tr key={index}>
                        <td>{item.course_name}</td>
                        {/* <td> */}
                            {/* ⚙️ */}
                            {/* Coming Soon... */}
                        {/* </td> */}
                        
                        
                        <td>
                            <Link to={`/player?id=${item.added_by}`}>
                            {item.added_by}
                            </Link>
                        </td>
                    </tr>
                    ))
                )}
            </tbody>
        </table>
    )
}

export default AdminPageDisplayCourses;