import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { updateEventCompleteTrue } from "./../util/db";
import { Form } from "react-bootstrap";

function EventCompleteRoundTd(props) {
  
  const [pending, setPending] = useState(false);
  // const itemsAreEmpty = !items || items.length === 0;

  const onSubmit = (e) => {
    e.preventDefault();

    // Show pending indicator
    setPending(true);
    updateEventCompleteTrue(props.eventId)
        
    setPending(false)
  };

  return (
    <>
      <Card>
        <Card.Header
          as="h5"
          className="d-flex justify-content-between align-items-center"
          style={{background:"#2D083F", text:"white"}}
        >
          <Card.Title style={{color:"#ffffff"}}>
            Finalize Event
          </Card.Title>     
        </Card.Header>
        <Card.Body>
          {"Please click finalize to complete the event"}
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="formUserId">
              <Form.Control type="hidden" defaultValue={props.eventId} />
            </Form.Group>
            <Button
              style={{background:"#4D81F4"}}
              block={true}
              size="lg"
              type="submit"
              // disabled={pending}
              // disalbled = props.disabled
              disabled={props.disabled}
            >
              Finalize
              {pending && (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden={true}
                  className="ml-2"
                >
                  <span className="sr-only">Sending...</span>
                </Spinner>
              )}
            </Button>
          </Form>
          
            {/* text box: Please make sure all cards are submitted before finalizing the event */}
            {props.disabled && (
              <div style={{color:"#FF0000"}}>
                Please make sure all cards are submitted before finalizing the event
              </div>
            )}
        </Card.Body>
      </Card>
      <hr />
    </>
  );
}

export default EventCompleteRoundTd;
