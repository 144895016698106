import React from 'react'
import Form from 'react-bootstrap/Form'

function EventCreateChoosePayoutMethodsRow({selectedDivision,selectedDivisions,setSelectedDivisions,payoutMethods}){



    const handleWeeklyMethodChange = (e) => {
        const newSelectedDivisions = selectedDivisions.map((division) => {
            if (division.division_id === selectedDivision.division_id){
                division.weekly_payout_method = parseInt(e.target.value)
            }
            return division
        })

        //set selectedDivisions
        setSelectedDivisions(newSelectedDivisions)
    }

    const handleSeriesMethodChange = (e) => {
        const newSelectedDivisions = selectedDivisions.map((division) => {
            if (division.division_id === selectedDivision.division_id){
                division.series_payout_method = parseInt(e.target.value)
            }
            return division
        })

        //set selectedDivisions
        setSelectedDivisions(newSelectedDivisions)
    }



    return(
        <tr>
            <td>
                <Form.Label>{selectedDivision.division_name}</Form.Label>
            </td>
            
            <td className='text-center'>
                <Form.Control as='select' onChange={handleWeeklyMethodChange}>
                    {payoutMethods.map((payoutMethod, index) => (
                        <option key={index} value={payoutMethod.id} name={payoutMethod.method_name} id={payoutMethod.id}>{payoutMethod.method_name}</option>
                    ))}
                </Form.Control>    
            </td>
            
            <td className='text-center'>
                <Form.Control as='select' onChange={handleSeriesMethodChange}>
                    {payoutMethods.map((payoutMethod, index) => (
                        <option key={index} value={payoutMethod.id} name={payoutMethod.method_name} id={payoutMethod.id}>{payoutMethod.method_name}</option>
                    ))}
                </Form.Control>      
            </td>
            
        </tr>
    )
    
}
export default EventCreateChoosePayoutMethodsRow