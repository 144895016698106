import React from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import FormAlert from "./FormAlert";
import { useEventsByOwner } from "./../util/db";
import "./DashboardItems.scss";
import { Link } from "react-router-dom";

function PlayersEvents(props) {
  
  const {
    data: items,
    status: itemsStatus,
    error: itemsError,
  } = useEventsByOwner(props.uid);

  const itemsAreEmpty = !items || items.length === 0;

  return (
    <>
      {itemsError && (
        <div className="mb-3">
          <FormAlert type="error" message={itemsError.message} />
        </div>
      )}

      {/* <div className="DashboardItems__card-items-wrapper"> */}
        <Card>
          <Card.Header
            as="h5"
            className="d-flex justify-content-between align-items-center"
            style={{background:"#2D083F", text:"white"}}
          >
            <Card.Title style={{color:"#ffffff"}}>
              Events
            </Card.Title>
          </Card.Header>

          {(itemsStatus === "loading" || itemsAreEmpty) && (
            <div className="py-5 px-3 align-self-center">
              {itemsStatus === "loading" && (
                <Spinner animation="border" variant="primary">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}

              {itemsStatus !== "loading" && itemsAreEmpty && (
                <>No events played yet</>
              )}
            </div>
          )}

          {itemsStatus !== "loading" && items && items.length > 0 && (
            <ListGroup variant="flush">
              {items.map((item, index) => (
                <ListGroup.Item
                  key={index}
                  className={
                    `d-flex justify-content-between align-items-center` +
                    (item.featured ? " featured" : "")
                  }
                >
                  <Link to={`/event?event=${item.event_id}`}>
                  
                  {item.events.name}
                  </Link> 
                  {item.score ? (
                    <span className="text-muted ml-2">
                      {item.score}
                    </span>
                  ) : (
                    <></>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Card>
      {/* </div> */}


    </>
  );
}

export default PlayersEvents;
