import AdminPageDisplayCourses from "./AdminPageDisplayCourses"
import HrCustom from "./HrCustom"
import AdminPageCourseAdd from "./AdminPageCourseAdd"

function AdminPageCourses({auth}) {
    return (
        <>
        <AdminPageDisplayCourses />
        <HrCustom />
        <AdminPageCourseAdd auth={auth} />
        </>
    )
}

export default AdminPageCourses