import { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useEventScoringFormat, updateEventScoringFormat } from '../util/db';

function EventManageScoringChange({eventId}) {
    const [eventScoring, setEventScoring] = useState(true);
    
    const [isEditted, setIsEditted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { data: items, status: itemsStatus } = useEventScoringFormat(eventId);

    //if items set scoring format to either true or false based on item[0].scoring_order_golf
    useEffect(() => {
        if (itemsStatus === 'success' && items.length > 0) {
            setEventScoring(items[0].scoring_order_golf);
        }
    }, [items, itemsStatus]);

    const onEventScoringChange = (e) => {
        setEventScoring(!eventScoring);
        setIsEditted(true);
        setIsSubmitted(false);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        await updateEventScoringFormat(eventId, eventScoring);
        setIsSubmitted(true);
        setIsSubmitting(false);
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="name">
                <Row>
                <Col>
                    <Form.Label style={{ color: "#2D083F" }}>Event Scoring</Form.Label>
                </Col>
                <Col className="d-flex align-items-center">
                    <span className="mr-2">Point Scoring</span>
                    <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={eventScoring}
                    onChange={onEventScoringChange}
                    className="mx-2"
                    />
                    <span className="ml-2">Golf Scoring</span>
                </Col>
                <Col>
                    <Button type="submit" disabled={!isEditted || isSubmitting}>
                    {isSubmitted ? '✔' : isSubmitting ? <Spinner animation="border" /> : 'Update'}
                    </Button>
                </Col>
                </Row>
            </Form.Group>
        </Form>
    );
}


export default EventManageScoringChange;