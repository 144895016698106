import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEventEntriesScoreboard } from "../util/db";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import Table from "react-bootstrap/Table";
import { useState } from 'react';
import DisplayScoreboardRow from './DisplayScoreboardRow';

function DisplayScoreboard(props) {

    const eventId = props.eventId
    const [numHoles,setNumHoles] = useState(20);

    // get scorecard for this evententryid
    const {
        data: scoreBoard,
        status: scoreBoardStatus,
        error: scoreBoardError,
    } = useEventEntriesScoreboard(eventId);

    //organize scoreboard by total score, highest total score at the top
    if (scoreBoard) {
        scoreBoard.sort((a,b) => {
            const aTotal = a.hole_scores.reduce((a,b) => {
                return a + b.score
            }
            ,0)

            const bTotal = b.hole_scores.reduce((a,b) => {
                return a + b.score
            }
            ,0)

            return bTotal -  aTotal
        })
    }



    return (
        <>
            <Row>
                <Col>
                    {scoreBoardStatus === "loading" && (
                        <div className="py-5 px-3 align-self-center">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}

                    {scoreBoardStatus === "error" && (
                        <div className="py-5 px-3 align-self-center">
                            <FormAlert type="error" message={scoreBoardError.message} />
                        </div>
                    )}

                    {scoreBoardStatus === "success" && scoreBoard && scoreBoard.length === 0 && (
                        
                        <div className="py-5 px-3 align-self-center">
                            Nobody playing yet. Please check back later.
                            </div>
                    )}

                    {scoreBoardStatus === "success" && scoreBoard && scoreBoard.length > 0 && (
                        <>
                            {scoreBoard[0].hole_scores.length > numHoles && (
                                setNumHoles(scoreBoard[0].hole_scores.length)
                            )}

                            <Table responsive style={{ tableLayout: "auto", border: "2px solid #2D083F", background:"#FFFFFF"}} >
                                <thead style={{background:"#2D083F", text:"white"}}>
                                    <tr>
                                        <th className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", position:'sticky',left:'0px', background:"#2D083F" }}></th>
                                        {[...Array(numHoles)].map((x, index) =>

                                            <th className="text-center align-middle" key={index} style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F",
                                                color:(index+1)%4===1 ? "#FFFFFF" : (index+1)%4===2 ? "#000000" : (index+1)%4===3 ? "#FFFFFF" : "#000000" , 
                                                background: (index+1)%4===1 ? "#FF0000" : (index+1)%4===2 ? "#FFFFFF" : (index+1)%4===3 ? "#0000FF" : "#FFFF00"  }}>
                                                    {index+1}
                                            </th>
                                        )}                                                
                                        <th className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"white" }}>Total</th>
                                        
                                        <th className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"white" }}>&#9734;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scoreBoard.map((item,index) => (
                                        <DisplayScoreboardRow key={index} item={item} numHoles={numHoles} eventId={eventId}/>
                                    ))}
                                </tbody>
                            </Table>
                            {/* <br /> */}
                        </>
                    )} 
                </Col>
            </Row>
        </>
    );
}

export default DisplayScoreboard;