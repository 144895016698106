import React from 'react'
import { updatePaymentRefund } from '../util/db'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { apiRequest } from '../util/util'
import AdminPagePaymentsCopyChargeButton from './AdminPagePaymentsCopyChargeButton';
import AdminPagePaymentsCopyCustomerButton from './AdminPagePaymentsCopyCustomerButton';
import AdminPagePaymentsCopyPaymentIntentButton from './AdminPagePaymentsCopyPaymentIntentButton';

function AdminPagePaymentsRow(props) {

    const [isRefunding, setIsRefunding] = React.useState(false);

    async function refundPayment(id) {
        setIsRefunding(true)

        const session = await apiRequest("stripe-create-refund", "POST", {
            payment_intent: id,
        });

        //call updatepaymentrefund
        updatePaymentRefund(id, session.amount).then((response) => {
            setIsRefunding(false)
        });
    }

    const item = props.item
    const key = props.index
    
    return (
        <tr key={key}>
            <td>
                {/* //if amount - refunded > 0, AND not already refunding show refund */}
                {(item.amount_total - item.amount_refunded > 0) &&!isRefunding && (
                    <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                            refundPayment(item.payment_intent)
                        }}
                    >
                        Refund
                    </button>
                )}

                {/* if amount has already been fully refunded */}
                {item.amount_total - item.amount_refunded <= 0 && (
                    <button
                        className="btn btn-sm btn-danger"
                        disabled
                    >
                        Refunded
                    </button>
                )}

                {/* if refund is in progress */}
                {(item.amount_total - item.amount_refunded > 0) &&isRefunding && (
                    <button
                        className="btn btn-sm btn-danger"
                        disabled
                    >
                        Refunding
                    </button>
                )}

            </td>
            <td>
                {item.event_entries && item.event_entries[0] && item.event_entries[0].users_public && (
                    <Link to={`/player?id=${item.event_entries[0].user_id}`}>{item.event_entries[0].users_public.name}</Link>
                )}
            </td>
            <td>
                {item.event_entries && item.event_entries[0] && item.event_entries[0].event_id && (
                    <Link to={`/event?event=${item.event_entries[0].event_id}`}>{item.event_entries[0].events.name}</Link>
                )}
            </td>
            <td>{new Date(item.created_at).toLocaleString()}</td>
            <td>{item.amount_total}</td>
            {/* <td>{item.charge}</td> */}
                {/* show first 11 digits followed by ... and a copy icon to copy entire string */}
                <td>
                    {item.charge && (
                        <AdminPagePaymentsCopyChargeButton charge={item.charge} />
                    )}
                </td>


            {/* <td>{item.customer}</td> */}
            {/* show first 11 digits followed by ... and a copy icon to copy entire string */}
            <td>
                {item.customer && (
                    <AdminPagePaymentsCopyCustomerButton customer={item.customer} />
                )}
            </td>

            {/* <td>{item.payment_intent}</td> */}
            <td>
                {item.payment_intent && (
                    <AdminPagePaymentsCopyPaymentIntentButton customer={item.payment_intent} />
                )}
            </td>
            <td>{item.currency}</td>
            <td>{item.payment_status}</td>
            <td>{item.amount_refunded}</td>

        </tr>
    )
}

export default AdminPagePaymentsRow