import AdminPageDisplayHoles from "./AdminPageDisplayHoles";
import HrCustom from "./HrCustom";
import AdminPageCreateHole from "./AdminPageCreateHole";

function AdminPageHoles({auth}) {
    return (
        <>
            <AdminPageDisplayHoles />
            <HrCustom />
            <AdminPageCreateHole auth={auth} />
        </>
    )
}

export default AdminPageHoles;