import React from 'react';
import ScorecardScoreButton from './ScorecardScoreButton';

function ScorecardRightScoreTd({ isComplete, hole_scores_id, onClickLockedScore, onRightScore }) {

  return (
    isComplete
        ?
          <td onClick={()=>onClickLockedScore()}>
            <ScorecardScoreButton backgroundColor={'#BFBFBF'} textColor={'#2D083F'} text={'+'} />
          </td>
        :
          hole_scores_id !== null 
            ?
              <td onClick={() => onRightScore()}>
                <ScorecardScoreButton backgroundColor={'#4D81F4'} textColor={'#d9e3ff'} text={'+'} />
              </td>
            : 
              <td onClick={() => onRightScore()}>
                <ScorecardScoreButton backgroundColor={'red'} textColor={'#d9e3ff'} text={'+'} />
              </td>
  );
}

export default ScorecardRightScoreTd;