import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useGroupScorecardPlayer } from '../util/db';

function ScorecardSummaryTable5x4({ eventEntryId }) {
    const { data: holes } = useGroupScorecardPlayer(eventEntryId);
    const [holeScores, setHoleScores] = useState([]);
    const[numReds, setNumReds] = useState(0)
    const[numWhites, setNumWhites] = useState(0)
    const[numBlues, setNumBlues] = useState(0)
    const[numYellows, setNumYellows] = useState(0)
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (holes) {
        const scores = [];
        let scores_total = 0;
        holes.forEach((hole) => {
            scores.push({ hole_order: hole.layout_holes.hole_order, score: hole.score });
            scores_total += hole.score;
        });

        scores.sort((a, b) => a.hole_order - b.hole_order);

        setHoleScores(scores);
        setTotal(scores_total);
        


            //reds = 1,5,9,13,17
            //whites = 2,6,10,14,18
            //blues = 3,7,11,15,19
            //yellows = 4,8,12,16,20

            let reds = 0 
            let whites = 0
            let blues = 0
            let yellows = 0

            scores.forEach((hole, index) => {
                if(hole.hole_order === 1 || hole.hole_order === 5 || hole.hole_order === 9 || hole.hole_order === 13 || hole.hole_order === 17){
                    reds += hole.score
                } else if(hole.hole_order === 2 || hole.hole_order === 6 || hole.hole_order === 10 || hole.hole_order === 14 || hole.hole_order === 18){
                    whites += hole.score
                } else if(hole.hole_order === 3 || hole.hole_order === 7 || hole.hole_order === 11 || hole.hole_order === 15 || hole.hole_order === 19){
                    blues += hole.score
                } else if(hole.hole_order === 4 || hole.hole_order === 8 || hole.hole_order === 12 || hole.hole_order === 16 || hole.hole_order === 20){
                    yellows += hole.score
                }
            })

            setNumReds(reds)
            setNumWhites(whites)
            setNumBlues(blues)
            setNumYellows(yellows)

        }
    }, [holes]);


  return (
    <Table responsive bordered style={{ tableLayout: "auto", border: "2px solid #2D083F" }}>
      <thead>
        <tr>
          <th className='text-center' style={{ backgroundColor: 'red', color: 'white' }}>{numReds}</th>
          <th className='text-center' style={{ backgroundColor: 'white', color: 'black' }}>{numWhites}</th>
          <th className='text-center' style={{ backgroundColor: 'blue', color: 'white' }}>{numBlues}</th>
          <th className='text-center' style={{ backgroundColor: 'yellow', color: 'black' }}>{numYellows}</th>
        </tr>
      </thead>
      <tbody>
        {[...Array(5)].map((_, rowIndex) => (
          <tr key={rowIndex}>
            {holeScores.slice(rowIndex * 4, rowIndex * 4 + 4).map((hole, index) => (
              <td
                key={index}
                className="text-center align-middle position-relative"
                style={{
                  width: "50px",
                  height: "50px",
                  tableLayout: "auto",
                  border: "2px solid #2D083F",
                  cursor: 'pointer',
                }}
              >
                <div
                  className="position-absolute"
                  style={{
                    top: '0',
                    right: '0',
                    width: '20px',
                    height: '20px',
                    backgroundColor: index === 0 ? 'red' : index === 1 ? 'white' : index === 2 ? 'blue' : 'yellow',
                    color: index === 1 || index === 3 ? 'black' : 'white',
                    border: '1px solid black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    margin: '0',
                    padding: '0',
                    borderBottomLeftRadius: '10px'
                  }}
                >
                  {hole.hole_order}
                </div>
                {/* 🚫 */}
                {hole.score === 5 ? '⭐️' : hole.score}

              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4" className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F" }}>
            {total}
          </td>
        </tr>
        </tfoot>
    </Table>
  );
}

export default ScorecardSummaryTable5x4;