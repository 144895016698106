import React from 'react';
import { useHoles } from '../util/db';
import { Table } from 'react-bootstrap';
import AdminPageDisplayHolesRow from './AdminPageDisplayHolesRow';

function AdminPageDisplayHoles(){
    
    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = useHoles()

    return (

        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Length</th>
                    <th>Description</th>
                    <th>Par</th>
                    <th>Setup Notes</th>
                </tr>
            </thead>
            <tbody>
                {itemsStatus !== "loading" && items && items.length > 0 && (
                    items.map((item, index) => (
                        <AdminPageDisplayHolesRow item={item} key={index} />
                    ))
                )}
            </tbody>
        </Table>
    )
}

export default AdminPageDisplayHoles