import React from 'react'
import { approveTdApplication, denyTdApplication,updateUserIsTd } from '../util/db'

function AdminPageApproveTdsRow({item}) {

    function onApprove () {
        //approve application, and when response is successful, alert user
        updateUserIsTd(item.user_id).then(() => {
            approveTdApplication(item.user_id).then(() => {
                alert("TD Approved!")
            }).catch((error) => {
                alert(error.message)
            })
        }).catch((error) => {
            alert(error.message)
        })
        // alert("TD Approved!")
    }

    function onDeny () {
        denyTdApplication(item.user_id).then(() => {
            alert("TD Denied!")
        }).catch((error) => {
            alert(error.message)
        })
        // alert("TD Denied!")
    }

  return (
    <tr>
        <td>
            {item.users_public.name !== null && item.users_public.name}
        </td>
        <td>
            {item.td_notes !== null && item.td_notes}
        </td>
        <td>
            <button className='btn' style={{backgroundColor:'#2D083F', color:'white'}} onClick={onApprove} >Approve</button>
        </td>
        <td>
            <button className='btn' style={{backgroundColor:'#2D083F', color:'white'}} onClick={onDeny} >Deny</button>
        </td>
    </tr>
  )
}

export default AdminPageApproveTdsRow