import React from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import EventCreateChooseLayoutsRow from './EventCreateChooseLayoutsRow';

function EventCreateChooseLayoutsTable({selectedDivisions,setSelectedDivisions,layouts}){

    return (
        <Table responsive className='table table-striped'>
            <thead style={{background:"#2D083F"}}>
                <tr>
                    <td className='text-center'>
                        <Form.Label style={{color:'#ffffff'}}>Division</Form.Label>
                    </td>
                    <td className='text-center'>
                        <Form.Label style={{color:'#ffffff'}}>Layout</Form.Label>
                    </td>

                </tr>
            </thead>
            <tbody>
                {selectedDivisions.map((selectedDivision, index) => (
                    <EventCreateChooseLayoutsRow key={index} selectedDivision={selectedDivision} selectedDivisions={selectedDivisions} setSelectedDivisions={setSelectedDivisions} layouts={layouts} />
                ))}
            </tbody>
        </Table>
    )

}

export default EventCreateChooseLayoutsTable;