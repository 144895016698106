import React from 'react';
import Table from 'react-bootstrap/Table';
import AdminPageApproveLdsRow from './AdminPageApproveLdsRow';
import { useIsTdApplicationPending } from '../util/db';

function AdminPageApproveLdsTable(){

    const {
        data: items,
        // status: itemsStatus,
        // error: itemsError,
    } = useIsTdApplicationPending();

    return (
        <Table responsive className='table' >
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Notes</th>
                    <th>Approve</th>
                    <th>Deny</th>
                </tr>
            </thead>
            <tbody>
                {items && items.map((item,index) => (
                    <AdminPageApproveLdsRow item={item} key={index} />
                ))}
                {items && items.length === 0 && (
                <tr>
                    <td colSpan="4">No LD Applications Pending</td>
                </tr>
                )}
            </tbody>
      </Table>
    );
}

export default AdminPageApproveLdsTable;