import React from 'react'
import { Tabs,Tab, Container } from 'react-bootstrap';
import AdminPageEvents from './AdminPageEvents';
import AdminPageApproveLds from './AdminPageApproveLds';
import AdminPageCourses from './AdminPageCourses';
import AdminPagePayments from './AdminPagePayments';
import AdminPageLayouts from './AdminPageLayouts';
import AdminPageHoles from './AdminPageHoles';

function AdminPageContent({auth, isAdmin}) {
  return (
    isAdmin && (<Container>
        <Tabs
            defaultActiveKey="events"
            id="uncontrolled-tab-example"
            className="mb-3 mt-3"
            fill
        >
            <Tab eventKey="events" title="Events">
                <AdminPageEvents auth={auth} />
            </Tab>
            <Tab eventKey="approve" title="LDs">
                <AdminPageApproveLds />
            </Tab>
            <Tab eventKey="courses" title="Courses">
                <AdminPageCourses auth={auth} />
            </Tab>
            <Tab eventKey="layouts" title="Layouts">
                <AdminPageLayouts auth={auth} />
            </Tab>
            <Tab eventKey="holes" title="Holes">
                <AdminPageHoles auth={auth} />
            </Tab>
            <Tab eventKey="payments" title="Payments">
                <AdminPagePayments />
            </Tab>
            {/* <Tab eventKey="players" title="Edit Players">
            <EventManagePlayers items={playersItems} eventId={eventId} scoringOrderGolf={scoringOrderGolf} />
            </Tab>
            <Tab eventKey="chat" title="Chat">
            <EventManageChat eventId={eventId} uid={uid}/>
            </Tab>
            <Tab eventKey="settings" title="Settings">
            <EventManageSettings eventId={eventId} uid={uid}/>
            </Tab>
            <Tab eventKey="complete" title="Complete">
            <EventManageComplete eventId={eventId} isEventComplete={isEventComplete}/>
            </Tab> */}
        </Tabs>
    </Container>)
  )
}

export default AdminPageContent