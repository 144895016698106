import React from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import EventCreateChooseCoursesRow from './EventCreateChooseCoursesRow';
// import EventCreateChooseLayoutsRow from './EventCreateChooseLayoutsRow';

function EventCreateChooseCoursesTable({courses,selectedVenue,setSelectedVenue,setSelectedVenueName,selectedVenueName}){
    // function EventCreateChooseCoursesTable({selectedDivisions,setSelectedDivisions,payoutMethods,courses}){
    return (
        <Table responsive className='table table-striped'>
            <thead style={{background:"#2D083F"}}>
                <tr>

                    <td className='text-center'>
                        <Form.Label style={{color:'#ffffff'}}>Venue</Form.Label>
                    </td>

                </tr>
            </thead>
            <tbody>
                <EventCreateChooseCoursesRow selectedVenueName={selectedVenueName} courses={courses} setSelectedVenue={setSelectedVenue} selectedVenue={selectedVenue} setSelectedVenueName={setSelectedVenueName}/>
            </tbody>
        </Table>
    )

}

export default EventCreateChooseCoursesTable;