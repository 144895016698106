import React from 'react'
import EventManageNameChange from './EventManageNameChange'
import EventManageDateChange from './EventManageDateChange'
import EventManageVenueChange from './EventManageVenueChange'
import EventManageLayoutsChange from './EventManageLayoutsChange'
import HrCustom from './HrCustom'
import EventManageGameChange from './EventManageGameChange'
import EventManageScoringChange from './EventManageScoringChange'

function EventManageSettings({eventId}) {

    return (
        <>
            <h3 className="text-center">
                General
            </h3>
            <EventManageNameChange eventId={eventId} />
            <EventManageDateChange eventId={eventId} />
            <EventManageVenueChange eventId={eventId} />
            <EventManageGameChange eventId={eventId} />
            <EventManageScoringChange eventId={eventId} />
            {/* hr */}
            <HrCustom />
            <EventManageLayoutsChange eventId={eventId} />
            {/* <EventManageGolfScoringChange eventId={eventId} /> */}
        </>
    )
}

export default EventManageSettings