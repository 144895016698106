import React from 'react'
import HrCustom from './HrCustom';
import AdminPageDisplayLayouts from './AdminPageDisplayLayouts';
import AdminPageLayoutAdd from './AdminPageLayoutAdd';

function AdminPageLayouts({auth}) {

    return (
        <>
            <AdminPageDisplayLayouts />
            <HrCustom />
            <AdminPageLayoutAdd auth={auth} />
        </>
    )
}

export default AdminPageLayouts