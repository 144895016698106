import React from 'react';
import { useLayouts } from '../util/db';
import { Link } from 'react-router-dom';

function AdminPageDisplayLayouts(){
    
    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = useLayouts()

    return (
        <table className="table">
            <thead>
                <tr>
                    <th>Edit</th>
                    <th>Layout</th>
                    <th>Course</th>
                    <th>Added By?</th>
                </tr>
            </thead>
            <tbody>
                {itemsStatus !== "loading" && items && items.length > 0 && (
                    items.map((item, index) => (
                    <tr key={index}>
                        <td>
                            <Link to={`/edit_layout?id=${item.layout_id}`}>
                            {/* Edit */}
                            {/* pencil emoji */}
                            ✏️
                            </Link>
                        </td>
                        <td>{item.layout_name}</td>
                        {/* <td> */}
                            {/* ⚙️ */}
                            {/* Coming Soon... */}
                        {/* </td> */}
                        
                        <td>
                            {/* <Link to={`/course?id=${item.course_id}`}> */}
                            {item.courses.course_name}
                            {/* </Link> */}
                        </td>
                        
                        <td>
                            <Link to={`/player?id=${item.added_by}`}>
                            {item.added_by}
                            </Link>
                        </td>
                    </tr>
                    ))
                )}
            </tbody>
        </table>
    )
}

export default AdminPageDisplayLayouts;