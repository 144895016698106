import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function DashboardAccountInfo({ auth }) {

    const date = new Date(auth.user.created_at); // Replace with your date variable

    // Extract month, day, and year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    
    // Format the date as MM-DD-YYYY
    const formattedDate = `${month}-${day}-${year}`;

    return (
        <Card>
            <Card.Body>
              <h5 className="mb-3">Account Info</h5>
              <div className="mt-4">
                <div>
                  Welcome, {auth.user.name}!
                </div>
                <div>
                  You are signed in as <strong>{auth.user.email}</strong>.
                </div>
                <div>
                  Member Since: {formattedDate}

                </div>
                <div className="mt-3">
                  You can change your account info{` `}
                  in{` `}
                  <Link to="/settings/general">
                    <strong>settings</strong>
                  </Link>
                </div>
              </div>
            </Card.Body>
        </Card>
    );
}

export default DashboardAccountInfo;