import React from 'react'
import Form from 'react-bootstrap/Form'
import { useState } from 'react';

function EventCreateChooseDivisionCheckbox({ division, selectedDivisions, setSelectedDivisions}) {
    //state for isChecked
    const [isChecked, setIsChecked] = useState(false)

    //useeffect. if division.id is in selectedDivisions, set isChecked to true
    React.useEffect(() => {
        if (selectedDivisions.some(selectedDivision => selectedDivision.division_id === division.id)) {
            setIsChecked(true)
        }
    }, [selectedDivisions, division.id])

    //handle checkbox change
    const handleCheckboxChange = () => {

        //if this division is already in selected divisions, remove it
        if (isChecked) {
            setSelectedDivisions(selectedDivisions.filter((selectedDivision) => selectedDivision.division_id !== division.id))
        } else {
            setSelectedDivisions([...selectedDivisions, { division_id: division.id, division_name:division.name, weekly_payout: 0, series_payout: 0, event_costs: 0, weekly_payout_method: 1, series_payout_method: 1, layout_id: 1}])
        }

        setIsChecked(!isChecked)
    }

  return (
    <Form.Check type="checkbox" label={division.name} onChange={handleCheckboxChange} id={division.id} checked={isChecked} />
  )
}
export default EventCreateChooseDivisionCheckbox