import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { usePayoutMethods, useDivisions, createEvent, createEventDivisions, useCourses,useGames } from '../util/db'
import EventCreateChooseDivisions from './EventCreateChooseDivisions'
import EventCreateChooseFeesTable from './EventCreateChooseFeesTable'
import EventCreateDisplayFeesTable from './EventCreateDisplayFeesTable'
import Alert from 'react-bootstrap/Alert'
import EventCreateChoosePayoutMethodsTable from './EventCreateChoosePayoutMethodsTable'
import { useLayouts } from '../util/db'
import EventCreateChooseLayoutsTable from './EventCreateChooseLayoutsTable'
import EventCreateChooseCoursesTable from './EventCreateChooseCoursesTable'
import SectionHeader from './SectionHeader'

function EventCreateForm({ uid }) {
    const numSteps = 7
    let todaysDate = new Date().toISOString().slice(0, 10)

    const [formDate, setFormDate] = useState(todaysDate)
    const [formName, setFormName] = useState('')
    const [formIsReplay, setFormIsReplay] = useState(false)
    const [formIsGolfScoring, setFormIsGolfScoring] = useState(false)
    const [formGame, setFormGame] = useState(1)
    const [step, setStep] = useState(1);
    const [selectedDivisions, setSelectedDivisions] = useState([])
    const [selectedVenue, setSelectedVenue] = useState(1)
    const [selectedVenueName, setSelectedVenueName] = useState('')

    //get available divisions useDivisions
    const {  
        data: divisions,
        status: divisionsStatus,
        // error: divisionsError 
    } = useDivisions();

    //get available payout methods usePayoutMethods
    const {
        data: payoutMethods,
        // status: payoutMethodsStatus,
        // error: payoutMethodsError
    } = usePayoutMethods();

    //get available layouts useLayouts
    const {
        data: layouts,
        status: layoutsStatus,
        // error: layoutsError
    } = useLayouts();

    const {
        data: courses,
        status: coursesStatus,
        // error: coursesError
    } = useCourses();

    const {
        data: games,
        // status: gamesStatus,
        // error: gamesError
    } = useGames();

    //create event_divisions data array
    let eventDivisionsData = []

    //handle submit function
    const handleSubmit = (e) => {
        e.preventDefault();
        
        //create event data array
        let eventData = {
            name: formName,
            date: formDate,
            is_rebuys: formIsReplay,
            scoring_order_golf: formIsGolfScoring,
            td_id: uid,
            course_id: selectedVenue,
            game_id: formGame
        }

        selectedDivisions.forEach(division => {

            var totalCost = parseInt(division.weekly_payout,10) + parseInt(division.series_payout,10) + parseInt(division.event_costs,10)

            eventDivisionsData.push({
                division_id: division.division_id,
                division_cost: totalCost,
                series_payout_rake_per_player: division.series_payout,
                division_rake_per_player: division.event_costs,
                layout_id: division.layout_id
            })
        })

        //add eventData to events table. get event_id for eventDivisionsData, wait for response, then
        createEvent(eventData).then((eventResponse) => {
            console.log(eventResponse[0].id)
            
            //add event to eventDivisionsData 
            eventDivisionsData.forEach(eventDivision => {
                eventDivision.event_id = eventResponse[0].id
            })


            //add eventDivisions to event_divisions table
            createEventDivisions(eventDivisionsData).then((eventDivisionsResponse) => {
                
                // redirect to event_manage page
                window.location.href = '/event_manage?event=' + eventResponse[0].id
            })

        })
    }

    const handleNext = () => {
        setStep(step + 1);
    };
    
    const handlePrevious = () => {
        setStep(step - 1);
    };

    const handleFormDateChange = (e) => {
        setFormDate(e.target.value)
    }
    
    const handleFormNameChange = (e) => {
        setFormName(e.target.value)
    }
    
    const handleFormIsReplayChange = (e) => {
        setFormIsReplay(e.target.checked)
    }
    
    const handleFormIsGolfScoringChange = (e) => {
        setFormIsGolfScoring(e.target.checked)
    }

    const handleFormGameChange = (e) => {
        setFormGame(e.target.value)
    }

    return (
        <Container>
            
            <Form>
                
                {step === 1 && (
                    <>
                    <SectionHeader
                        title={'Create Event'}
                        // subtitle={props.subtitle}
                        size={1}
                        spaced={true}
                        className="text-center mt-3 mb-3"
                    />
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Event Name</Form.Label>
                            <Form.Control type="text" onChange={handleFormNameChange} required value={formName} />
                            {/* <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                            </Form.Text> */}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="date">
                            <Form.Label>Date</Form.Label>
                            <Form.Control type="date" placeholder="date" onChange={handleFormDateChange} required value={formDate}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="game">
                            <Form.Label>Game</Form.Label>
                            <Form.Control as="select" onChange={handleFormGameChange} value={formGame}>
                                {games && games.map(game => (
                                    <option key={game.id} value={game.id}>{game.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="isReplay">
                            <Form.Check type="switch" label="Allow Replays?" onChange={handleFormIsReplayChange} value={formIsReplay} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="isGolfScoring">
                            <Form.Check type="switch" label="Golf Scoring?" onChange={handleFormIsGolfScoringChange} value={formIsGolfScoring} />
                        </Form.Group>

                       
                    </>
                )}
                {step === 2 && (
                    <>
                    <SectionHeader
                        title={'Divisions'}
                        subtitle={'Please choose divisions for this event'}
                        size={1}
                        spaced={true}
                        className="text-center mt-3 mb-3"
                    />

                        <div className='mb-2'>
                            {divisionsStatus === 'loading' && (
                                <div>Loading...</div>
                            )}
                            {divisionsStatus === 'error' && (
                                <div>Error...</div>
                            )}
                            {divisionsStatus === 'success' && (
                                <EventCreateChooseDivisions divisions={divisions} selectedDivisions={selectedDivisions} setSelectedDivisions={setSelectedDivisions}/>
                            )}
                        </div>
                    </>
                )}

                {step === 3 && (
                    <>
                    <SectionHeader
                        title={'Fees'}
                        subtitle={'Please choose fees for this event'}
                        size={1}
                        spaced={true}
                        className="text-center mt-3 mb-3"
                    />


                        {selectedDivisions.length === 0 && (
                            <div className='mb-2'>
                                <Alert variant="danger">
                                    <p className='text-center'>Please go back and choose divisions for this event first</p>
                                </Alert>
                            </div>
                        )}

                        {selectedDivisions.length > 0 && (
                            <EventCreateChooseFeesTable selectedDivisions={selectedDivisions} setSelectedDivisions={setSelectedDivisions} payoutMethods={payoutMethods}/>
                        )}
                    </>
                )}

                {step === 4 && (    
                    <>
                        <SectionHeader
                            title={'Payout Methods'}
                            subtitle={'Please choose payout methods for this event'}
                            size={1}
                            spaced={true}
                            className="text-center mt-3 mb-3"
                        />

                        {selectedDivisions.length > 0 && (
                            <EventCreateChoosePayoutMethodsTable selectedDivisions={selectedDivisions} setSelectedDivisions={setSelectedDivisions} payoutMethods={payoutMethods}/>
                        )}
                    </>
                )}

                {step === 5 && (
                    <>
                    <SectionHeader
                        title={'Venue'}
                        subtitle={'Please choose the venue for this event'}
                        size={1}
                        spaced={true}
                        className="text-center mt-3 mb-3"
                    />
                        {coursesStatus === 'loading' && (
                            <div>Loading Venues...</div>
                        )}
                        {courses && 
                            <EventCreateChooseCoursesTable selectedVenueName={selectedVenueName} selectedVenue={selectedVenue} setSelectedVenue={setSelectedVenue} courses={courses} setSelectedVenueName={setSelectedVenueName}/>
                        }
                    </>
                )}

                {step === 6 && (
                    <>
                    <SectionHeader
                        title={'Layouts'}
                        subtitle={'Please choose the layouts for this event'}
                        size={1}
                        spaced={true}
                        className="text-center mt-3 mb-3"
                    />
                        {layoutsStatus === 'loading' && (
                            <div>Loading Layouts...</div>
                        )}
                        {layouts && selectedDivisions && 
                            <EventCreateChooseLayoutsTable selectedDivisions={selectedDivisions} setSelectedDivisions={setSelectedDivisions} layouts={layouts} /> 
                        }
                    </>
                )}

                {step === 7 && (
                    <>
                        <SectionHeader
                            title={'Summary'}
                            subtitle={'Please review the event details before creating'}
                            size={1}
                            spaced={true}
                            className="text-center mt-3 mb-2"
                        />
                        <hr/>
                        <div className='mb-2'>
                            <p className='text-center'>Event Name: {formName}</p>
                        </div>
                        <div className='mb-2'>
                            <p className='text-center'>Event Date: {formDate}</p>
                        </div>
                        <div className='mb-2'>
                            <p className='text-center'>Allow Replays: {formIsReplay ? 'Yes' : 'No'}</p>
                        </div>
                        <div className='mb-2'>
                            <p className='text-center'>Golf Scoring: {formIsGolfScoring ? 'Yes' : 'No'}</p>
                        </div>
                        <div className='mb-2'>
                            <p className='text-center'>Game: {games && games.map(game => game.id === formGame ? game.name : '')}</p>
                        </div>
                        <div className='mb-2'>
                            <p className='text-center'>Venue: 
                            {selectedVenueName}
                            </p>
                        </div>
                        <br />
                        <div className='mb-2'>
                            <p className='text-center'>Fees </p>
                            <EventCreateDisplayFeesTable layouts={layouts} payoutMethods={payoutMethods} selectedDivisions={selectedDivisions} setSelectedDivisions={setSelectedDivisions}/>
                        </div>
                        <hr/>
                    </>
                )}
                
                <div className="d-flex justify-content-between">
                    {step > 1 && (
                        <Button variant="secondary" onClick={handlePrevious}>
                            Previous
                        </Button>
                    )}
                    {step < numSteps && (
                        step === 1 && formName === '' ? (
                            <Button variant="primary" disabled>
                                Next
                            </Button>
                        ) : step === 2 && selectedDivisions.length === 0 ? (
                            <Button variant="primary" disabled>
                                Next
                            </Button>
                        ) : (
                            <Button variant="primary" onClick={handleNext}>
                            Next
                            </Button>
                        )
                    ) }

                    {step === numSteps && (
                        <Button variant="primary" type="submit" onClick={handleSubmit}>
                            Create Event
                        </Button>
                    )}
                </div>

                {step === 1 && formName === '' && (
                    <Alert variant='danger' className='mt-2'>
                        <p className='text-center mb-0'>Please enter an event name to continue</p>
                    </Alert>
                )}

                {step === 2 && selectedDivisions.length === 0 && (
                    <Alert variant='danger' className='mt-2'>
                        <p className='text-center mb-0'>Please choose divisions for this event to continue</p>
                    </Alert>
                )}

                <hr />
                
                <Container className="text-center">
                    <p>Step {step} of {numSteps}</p>
                </Container>
                <ProgressBar now={(step / numSteps) * 100} />
            </Form>
        </Container>
    )
}

export default EventCreateForm