import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EventDivisions from "./EventDivisions"

function EventSection(props) {

  return (

      <>
        {
          props.isEventComplete ? <center><h6>Event is complete</h6></center> : <></>
        }
        <Row>
          <Col>
            <EventDivisions isEventComplete={props.isEventComplete} eventId={props.eventId} scoringOrderGolf={props.scoringOrderGolf}/>
          </Col>
        </Row>
      </>

  );
}

export default EventSection;
