import React from 'react';

function AdminPagePaymentsCopyCustomerButton (customer) {
    const [isCopied, setIsCopied] = React.useState(false);

    return (
        <>
            {customer.customer?.substring(0, 11)}...
            {isCopied && <button className="btn btn-sm btn-secondary"> Copied!</button>}
            {!isCopied && (
                <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                        setIsCopied(true);
                        navigator.clipboard.writeText(customer.customer)
                    }}
                >
                    Copy
                </button>
            )}
        </>
    );
};

export default AdminPagePaymentsCopyCustomerButton;