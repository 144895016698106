// Desc: Admin page to add a new layout
import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { useCourses } from "../util/db";
import AdminPageLayoutAddTable from "./AdminPageLayoutAddTable";
// get createlayout from db
import { createLayout,createLayoutHole } from "../util/db";

function AdminPageLayoutAdd({auth}) {

    const [layoutHoles, setLayoutHoles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);

    const {  
        data: courses,
        // status: coursesStatus,
        // error: divisionsError 
    } = useCourses();
   
    const [layoutName, setLayoutName] = useState('');
    const [course, setCourse] = useState(1);
    const [numHoles, setNumHoles] = useState(20);

    const handleSubmit = (e) => {
        e.preventDefault();

        const invalidHoles = layoutHoles.filter(hole => hole.hole_id === 0);

        if(invalidHoles.length > 0){
            alert('Please select a hole for each hole number');
            return;
        }

        setIsSubmitting(true);
        
        const newLayout = {
            layout_name: layoutName,
            course_id: course,
            added_by: auth.user.id
        }

        createLayout(newLayout).then(response => {
            const layout_id = response[0].layout_id;

            const newLayoutHoles = layoutHoles.map(hole => {
                return {
                    layout_id: layout_id,
                    hole_id: hole.hole_id,
                    hole_order: hole.hole_order
                }
            });

            // add each hole to layoutholes. Wait for response and set isSubmitted to true
            newLayoutHoles.forEach(hole => {
                createLayoutHole(hole).then(response => {
                    <div></div>
                });
            });
            
            setIsSubmitted(true);
            setIsSubmitting(false);
        });
    }

    const handleLayoutNameChange = (e) => {
        setLayoutName(e.target.value);
        if(e.target.value !== ''){
            setAllowSubmit(true);
        }
    }

    const handleCourseChange = (e) => {
        setCourse(e.target.value);
    }

    return(
        <Container>
            <Form>
                    <>
                        <div className='text-center mt-2'>
                            <h1>Create Layout</h1>
                        </div>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Layout Name</Form.Label>
                            <Form.Control type="text" onChange={handleLayoutNameChange} required value={layoutName} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="date">
                            <Form.Label>Number of Holes</Form.Label>
                            <Form.Control type="number" placeholder="#" onChange={(e) => setNumHoles(e.target.value)} value={numHoles} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="game">
                            <Form.Label>Course</Form.Label>
                            <Form.Control as="select" onChange={handleCourseChange} value={course}>
                                {courses && courses.map(course => (
                                    <option key={course.id} value={course.id}>{course.course_name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </>


                    <>
                        <div className='text-center mt-2'>
                            <h1>Holes</h1>
                        </div>

                        <AdminPageLayoutAddTable numHoles={numHoles} layoutHoles={layoutHoles} setLayoutHoles={setLayoutHoles}/>
                    </>


               
                <div className="d-flex justify-content-between">
                    {allowSubmit ? (
                        <Button variant="primary" type="submit" onClick={handleSubmit} disabled={isSubmitted || isSubmitting}>
                            {isSubmitted ? 'Submitted' : isSubmitting ? 'Submitting' : 'Create Layout'}
                        </Button>        
                    ) : (
                        <Button variant="primary" type="submit" onClick={handleSubmit} disabled>
                            Please complete form
                        </Button>        
                    )}   
                </div>

                {/* if name not valid, alert  */}
                {layoutName === '' && (
                    <div className='mt-2'>
                        <p className='text-center mb-0'>Please enter a layout name to continue</p>
                    </div>
                )}

            </Form>
        </Container>
    )
}

export default AdminPageLayoutAdd