import React from 'react'
import EventCompleteRoundTd from './EventCompleteRoundTd'
import EventCompleteRoundFalseTd from './EventCompleteRoundFalseTd'
// import EventCalculatePayoutsTd from './EventCalculatePayoutsTd'
import { Row,Col } from 'react-bootstrap'
import {  useGroupScorecardsNotComplete } from "./../util/db";
// import { updateEventCompleteTrue } from "./../util/db";

function EventManageComplete(props) {

  const {
    data: items,
    // status: itemsStatus,
    // error: itemsError,
    } = useGroupScorecardsNotComplete(props.eventId)

  let isLiveScorecards = 0

  //if there are items (hook is complete, no errors)loop through items
  if(items !== null && items !== undefined){
    items.forEach((item, index) => {
      //if group_scorecard_players is not empty, set isLiveScorecards to 1
      if(item.group_scorecard_players.length > 0){
        isLiveScorecards = 1
      }
    })
  }

  return (

      <Row>
        <Col lg={6}>
          {
            props.isEventComplete 
              ? 
                <EventCompleteRoundFalseTd 
                  eventId={props.eventId} 
                  isEventComplete={props.isEventComplete}
                /> 
              : //event is not complete. Show the complete button

              //if there are scorecards, disable the complete button
              isLiveScorecards > 0
                ?
                  <>
                    <EventCompleteRoundTd 
                      eventId={props.eventId} 
                      isEventComplete={props.isEventComplete}
                      disabled={true}
                    />
                  </>
                :
                  <EventCompleteRoundTd 
                    eventId={props.eventId} 
                    isEventComplete={props.isEventComplete}
                    disabled={false}
                  />
          }
        </Col>
        {/* <Col lg={6}>
            {<EventCalculatePayoutsTd eventId={props.eventId}/>}
        </Col> */}
      </Row>
 
  )
}

export default EventManageComplete