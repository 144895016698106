import React, { useEffect, useState } from 'react';
import { updateHoleName } from '../util/db';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

function AdminPageDisplayHolesRowName({ item }) {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(item.hole_name ? item.hole_name : "");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const inputRef = React.useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);
  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const onSubmitEdit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    updateHoleName(item.hole_id, text).then((res) => {
      setIsEditing(false);
      setIsSubmitting(false);
    });
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    isEditing ? 
        <td>
          <Form onSubmit={onSubmitEdit}>
            <Form.Control
              type="text"
              value={text}
              onChange={handleTextChange}
              ref={inputRef}
              disabled={isSubmitting}
            />

            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>

          </Form>
        </td>
    : 
      <td onDoubleClick={handleDoubleClick}>{text}</td>
  )
}

export default AdminPageDisplayHolesRowName;