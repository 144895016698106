import React from 'react'
import { Tabs } from 'react-bootstrap'
import { Tab } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import EventsPageUpcoming from '../components/EventsPageUpcoming'
import EventsPagePast from '../components/EventsPagePast'
import Meta from '../components/Meta'
import SectionHeader from '../components/SectionHeader'

function EventsPage() {
  
  return (
    <>
    <Meta title="Events" description="Disc Golf Scoring" />
      <SectionHeader
        title="Events"
        size={1}
        className="text-center mb-0 mt-4"
      />
      <Container className='mt-3 '>
        <Tabs defaultActiveKey="upcoming" variant='pills' id="uncontrolled-tab-example" fill>
          <Tab eventKey="upcoming" title="Upcoming">
            <EventsPageUpcoming />
          </Tab>
          <Tab eventKey="past" title="Past">
            <EventsPagePast />
          </Tab>
        </Tabs>
      </Container>
    </>
    )
}

export default EventsPage