import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import FormField from "./FormField";
import { useAuth } from "./../util/auth";
import { checkEmail } from "../util/db";

function AuthForm(props) {
  const auth = useAuth();

  const [pending, setPending] = useState(false);
  const { handleSubmit, register, errors, getValues } = useForm();

  const submitHandlersByType = {
    signin: ({ email, pass }) => {
      return auth.signin(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    signup: ({ email, pass }) => {
      return auth.signup(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },
    forgotpass: ({ email }) => {

      checkEmail(email).then((result) => {
        //if result is empty array, email does not exist
        if(result.length === 0){
          setPending(false);
          // Show error alert message
          props.onFormAlert({
            type: "error",
            message: "Email does not exist. Please create a new account. If you cannot find your confirmation email, please check spam folder.",
          });
        }else{
          console.log(result, 'result')
          console.log(result.length, 'result length')
          return auth.sendPasswordResetEmail(email).then(() => {
            setPending(false);
            // Show success alert message
            props.onFormAlert({
              type: "success",
              message: "Password reset email sent",
            });
          }); 
        } 
      }      
    )
      //original
      // return auth.sendPasswordResetEmail(email).then(() => {
      //   setPending(false);
      //   // Show success alert message
      //   props.onFormAlert({
      //     type: "success",
      //     message: "Password reset email sent",
      //   });
      // });
    },
    changepass: ({ pass }) => {
      return auth.confirmPasswordReset(pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Your password has been changed",
        });
      });
    },
  };

  // Handle form submission
  const onSubmit = ({ email, pass }) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      email,
      pass,
    }).catch((error) => {
      setPending(false);

      let errorMessageInvalidCredentials = "";

      if(error.message === "Invalid login credentials"){
        // errorMessageInvalidCredentials = "Invalid login credentials. Please double check your email and password. If this is your first time, please create a new account using the link below.";

        //check to see if email is valid.
        checkEmail(email).then((result) => {
          //if result is empty array, email does not exist
          if(result.length === 0){
            errorMessageInvalidCredentials = "Invalid login credentials. Email does not exist. If this is your first time, please create a new account using the link below.";
          }else{
            errorMessageInvalidCredentials = "Invalid login credentials. Please double check your email and password.";
          }
          // Show error alert message
          props.onFormAlert({
            type: "error",
            message: errorMessageInvalidCredentials,
          });
        });


        // // Show error alert message
        // props.onFormAlert({
        //   type: "error",
        //   message: errorMessageInvalidCredentials,
        // });
      }else{
        // Show error alert message
        props.onFormAlert({
          type: "error",
          message: error.message,
        });
      }

      
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>

      {["signup", "signin", "forgotpass"].includes(props.type) && (
        <Form.Group controlId="formEmail">
          <FormField
            size="lg"
            name="email"
            type="email"
            placeholder="Email"
            error={errors.email}
            inputRef={register({
              required: "Please enter an email",
            })}
          />
        </Form.Group>
      )}

      {["signup", "signin", "changepass"].includes(props.type) && (
        <Form.Group controlId="formPassword">
          <FormField
            size="lg"
            name="pass"
            type="password"
            placeholder="Password"
            error={errors.pass}
            inputRef={register({
              required: "Please enter a password",
            })}
          />
        </Form.Group>
      )}

      {["signup", "changepass"].includes(props.type) && (
        <>
        <Form.Group controlId="formConfirmPass">
          <FormField
            size="lg"
            name="confirmPass"
            type="password"
            placeholder="Confirm Password"
            error={errors.confirmPass}
            inputRef={register({
              required: "Please enter your password again",
              validate: (value) => {
                if (value === getValues().pass) {
                  return true;
                } else {
                  return "This doesn't match your password";
                }
              },
            })}
          />
        </Form.Group>



        </>



      )}

      <Button
        variant="primary"
        block={true}
        size="lg"
        type="submit"
        disabled={pending}
      >
        {!pending && <span>{props.buttonAction}</span>}

        {pending && (
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden={true}
            className="align-baseline"
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
      </Button>
    </Form>
  );
}

export default AuthForm;
