import { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useEventGame, useGames, updateEventGame } from '../util/db';

function EventManageGameChange({eventId}) {
    const [eventGame, setEventGame] = useState();
    
    const [games, setGames] = useState([]);
    const [isEditted, setIsEditted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { data: items, status: itemsStatus } = useEventGame(eventId);
    const { data: allGames, status: allGamesStatus } = useGames();

    useEffect(() => {
        if (itemsStatus !== "loading" && items && items.length > 0) {
            setEventGame(
               items[0].games.id
            );
        }
    }, [items, itemsStatus]);

    useEffect(() => {
        if (allGamesStatus !== "loading" && allGames && allGames.length > 0) {
            setGames(allGames);
        }
    }, [allGames, allGamesStatus]);

    const onEventVenueChange = (e) => {
        setEventGame(e.target.value);
        setIsEditted(true);
        setIsSubmitted(false);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        await updateEventGame(eventId, eventGame);
        setIsSubmitted(true);
        setIsSubmitting(false);
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="name">
                <Row>
                    <Col>
                        <Form.Label style={{color:"#2D083F"}}>Event Game</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control as="select" style={{color:"#2D083F"}} required value={eventGame} onChange={onEventVenueChange}>
                            {games.map((game, index) => (
                                <option key={index} value={game.id}>{game.name}</option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col>
                        <Button type="submit" disabled={!isEditted || isSubmitting}>
                            {isSubmitted ? '✔' : isSubmitting ? <Spinner animation="border" /> : 'Update'}
                        </Button>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    );
}

export default EventManageGameChange;