import { Table } from 'react-bootstrap';
import { useLayouts,useEventDivisions } from '../util/db';
import EventManageLayoutsChangeRow from './EventManageLayoutsChangeRow';

function EventManageLayoutsChange({eventId}) {
    
    const { 
        data: layouts, 
        // status: layoutsStatus 
    } = useLayouts();
    const { 
        data: divisions, 
        // status: divisionsStatus 
    } = useEventDivisions(eventId);

    return (
        <>
        {/* center */}
            <h3 className="text-center">
                Layouts
            </h3>        
            <Table bordered>
                <thead>
                    <tr>
                        <th>Division</th>
                        <th>Layout</th>
                        <th>Update</th>
                    </tr>
                </thead>
                <tbody>
                    {/* loop through divisions */}
                    {divisions?.map((division, index) => (
                        <EventManageLayoutsChangeRow key={index} division={division} layouts={layouts} />
                    ))}
                    
                </tbody>
            </Table>
        </>
    );
}

export default EventManageLayoutsChange;