import React from 'react'
import Form from 'react-bootstrap/Form'

function EventCreateChooseFeesRow({selectedDivision,selectedDivisions,setSelectedDivisions,payoutMethods}){

    const handleWeeklyPayoutChange = (e) => {
        const newSelectedDivisions = selectedDivisions.map((division) => {
            if (division.division_id === selectedDivision.division_id){
                division.weekly_payout = e.target.value
            }
            return division
        })

        //set selectedDivisions
        setSelectedDivisions(newSelectedDivisions)
    }

    const handleSeriesPayoutChange = (e) => {
        const newSelectedDivisions = selectedDivisions.map((division) => {
            if (division.division_id === selectedDivision.division_id){
                division.series_payout = e.target.value
            }
            return division
        })

        //set selectedDivisions
        setSelectedDivisions(newSelectedDivisions)
    }

    const handleEventCostChange = (e) => {
        const newSelectedDivisions = selectedDivisions.map((division) => {
            if (division.division_id === selectedDivision.division_id){
                division.event_costs = e.target.value
            }
            return division
        })

        //set selectedDivisions
        setSelectedDivisions(newSelectedDivisions)
    }

    return(
        <tr>
            <td>
                <Form.Label>{selectedDivision.division_name}</Form.Label>
            </td>
            <td className='text-center'>
                {/* sum of selectedDivision.weekly_payout + selecteddivision.series_payout + selecteddivision.event_costs */}
                ${parseInt(selectedDivision.weekly_payout) + parseInt(selectedDivision.series_payout) + parseInt(selectedDivision.event_costs)}
            </td>
            <td className='text-center'>
                {/* weekly payout */}
                <Form.Control type="number" min={0} value={selectedDivision.weekly_payout} onChange={handleWeeklyPayoutChange} />
            </td>
            <td className='text-center'>
                {/* series payout */}
                <Form.Control type="number" min={0} value={selectedDivision.series_payout} onChange={handleSeriesPayoutChange} />
            </td>
            <td className='text-center'>
                {/* event costs */}
                <Form.Control type="number" min={0} value={selectedDivision.event_costs} onChange={handleEventCostChange} />
            </td>
        </tr>
    )
    
}
export default EventCreateChooseFeesRow