import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { useRouter } from "../util/router"

function GroupScorecardView(props){
    const router = useRouter()

    const eventId = router.query.eventId
    const entryId = router.query.entryId
    // const scorecardId = router.query.scorecardId

    return (
        <>
            <div className="text-center">
                Scorecard Here
            </div>
            <div className="mt-2" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Link to={`/scorecard?event=${eventId}&entry=${entryId}`}>
                    Back to Score
                </Link>
            </div>
        </>
    )
}

export default GroupScorecardView