import { Table } from "react-bootstrap";
import { useEffect } from "react";
import { useHoles } from "../util/db";

function AdminPageLayoutAddTable({numHoles,layoutHoles,setLayoutHoles}) {

    const {
        data: availableHoles,
        // status: availableHolesStatus,
        // error: holesError
    } = useHoles();

    useEffect(() => {
        const newHoles = [];
        for (let i = 1; i <= numHoles; i++) {
            newHoles.push({hole_order: i, hole_id: 0});
        }
        setLayoutHoles(newHoles);
        // eslint-disable-next-line
    }, [numHoles]);


    const handleHoleChange = (e, index) => {
        const newHoles = [...layoutHoles];
        // newHoles[index].hole_id = e.target.value;
        //make sure e.target.value is a number
        newHoles[index].hole_id = parseInt(e.target.value);

        setLayoutHoles(newHoles);
    }

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Hole Number</th>
                    <th>Hole</th>
                </tr>
            </thead>
            <tbody>
                {layoutHoles.map((hole, index) => (
                    <tr key={index}>
                        <td>{hole.hole_order}</td>
                        <td>
                            <select value={hole.hole_id} onChange={(e) => handleHoleChange(e, index)}>
                                <option value={0}>Select Hole</option>
                                {availableHoles && availableHoles.map((hole,index) => (
                                    <option key={index} value={hole.hole_id}>{hole.hole_name} ({hole.hole_id})</option>
                                ))}
                            </select>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default AdminPageLayoutAddTable;