import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import { useEventDivisions } from './../util/db';
import { updateDivision } from './../util/db';

function EventManagePlayersChangeDivModal({divisionName,eventId,eventEntryId,eventDivisionsId,playerName}) {
    const [show, setShow] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState(eventDivisionsId);
    
    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = useEventDivisions(eventId);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function handleSave() {

        //updateDivison, then close modal
        updateDivision(eventEntryId,selectedDivision,eventId).then(
            handleClose()
            // console.log('sucess')
        ).catch((error) => {
            console.error('Error:', error);
        }
        );
    }

    const handleSetDivision = (event_divisions_id) => {
        setSelectedDivision(event_divisions_id)        
    }

      //return true if this is the selected division
    const isSelectedDivision = (this_event_divisions_id) => {
        if(selectedDivision === this_event_divisions_id){
            return true
        }else{
            return false
        }
    }

    return (
        <>
            <div onClick={handleShow}>
            ✏️
            </div>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Change Divisions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Player:{playerName}</p>
                    <p>Current Division:{divisionName}</p>
                    <hr/>
                    <p>
                    Please select a new division
                    </p>

                    <Container>
                        <ListGroup as='ul'>
                            {itemsStatus !== "loading" && items && items.length > 0 && (
                                items.map((item, index) => (
                                isSelectedDivision(item.event_divisions_id) ? <ListGroup.Item as='li' key={index} active style={{background:'#2D083F'}}>
                                    <p className="font-weight-bold mb-0" style={{color:'white'}}>
                                    {item.divisions.name}
                                    </p>
                                </ListGroup.Item>
                                : <ListGroup.Item as='li' key={index} onClick={() => handleSetDivision(item.event_divisions_id,item.division_cost,item.divisions.name,item.layout_id)}>
                                    <p className="font-weight-bold mb-0" style={{color:'#2d083f'}}>{item.divisions.name}</p>
                                </ListGroup.Item>
                            ))
                            )}
                        </ListGroup>
                    </Container>
                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button style={{background:"#4D81F4"}} onClick={handleSave}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EventManagePlayersChangeDivModal;