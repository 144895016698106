import React from 'react';
import Toast from 'react-bootstrap/Toast';

function ScorecardRowHoleinfoToast({player, currentHole, showHoleInfo, toggleHoleInfo, onViewEdits}) {

    return (
        <td colSpan={4}>
            <Toast delay={7000} autohide show={showHoleInfo} onClose={toggleHoleInfo}>
                <Toast.Header>
                    {player.event_entries.hole_scores.map((hole_score, index) => {
                    if (hole_score.layout_holes.hole_order === currentHole) {
                        return (
                        <div key={index}>
                            <strong className="me-auto">Hole {hole_score.layout_holes.holes.hole_name}</strong>
                        </div>
                        )
                    }
                    return (
                        null
                    )
                    })}
                </Toast.Header>
                <Toast.Body>
                    {player.event_entries.hole_scores.map((hole_score, index) => {
                    if (hole_score.layout_holes.hole_order === currentHole) {
                        return (
                        <div key={index}>
                            {hole_score.layout_holes.holes.hole_description ? hole_score.layout_holes.holes.hole_description : ''}
                        </div>
                        )
                    }
                    return (
                        null
                    )
                    })}
                    <div onClick={onViewEdits} style={{cursor:'pointer',float:'right'}}>
                    View Edits
                    </div>
                </Toast.Body>
            </Toast>
        </td>
    )
}

export default ScorecardRowHoleinfoToast;