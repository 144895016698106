import React from 'react'
import { useEventsAll } from '../util/db'
import AdminPageEventsRow from './AdminPageEventsRow'

function AdminPageEvents({auth}) {

    //look up all events in db
    const {
        data: items,
        status: itemsStatus,
        error: itemsError,
      } = useEventsAll();
    
    return (
        //map items to table. first column event name with link to event page, 2nd column settings gear with link to event_manage, second column event date, third column event status
        <>
            {itemsError && (
                <div className="mb-3">
                ...
                </div>
            )}
            <table className="table">
                <thead>
                    <tr>
                        <th>Event Name</th>
                        <th>LD</th>
                        <th>Manage</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {itemsStatus !== "loading" && items && items.length > 0 && (
                        items.map((item, index) => (
                            <AdminPageEventsRow key={index} item={item} />
                        ))
                    )}
                </tbody>
            </table>
        </>
    )
}

export default AdminPageEvents