import EventChatPostCreate from "./EventChatPostCreate";
import HrCustom from "./HrCustom";
import EventChatDisplayPostsTable from "./EventChatDisplayPostsTable";

function EventManageChat({eventId,uid}) {
  return (
    <>
        <EventChatPostCreate eventId={eventId} uid={uid} />
        <HrCustom width={'100%'} />
        <EventChatDisplayPostsTable eventId={eventId}/>
      </>
  );
}

export default EventManageChat;