import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function ScorecardSummaryFooterNavigation({eventId,emptyHoles,currentHole, onJumpToHole,onSubmitScorecard, isComplete}) {
    function onReturnToScoring(){
        // console.log(currentHole)
        onJumpToHole(currentHole)
    }

    function onAlertScoresMissing(){
        alert('Please fill in all scores before submitting. If a player DNF (did not finish), please DNF them from the Scorecard Settings page.')
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Button style={{background:'#4D81F4',fontWeight:'bold'}} onClick={() => onReturnToScoring()}>
                        Back to Scoring
                    </Button>
                </Col>
                {/* //centered button */}
                <Col className='text-center' >
                    
                    <Link to={'/event?event='+eventId}>
                    <Button style={{background:'#4D81F4',fontWeight:'bold'}}>        
                        Event Home
                        </Button>    
                    </Link>
                    
                </Col>
                {/* //col right aligned */}
                <Col className='text-right'>
                    {isComplete ?
                        <Button 
                            disabled
                            style={emptyHoles ? {background:'#FF0000',
                            border:"0"} : {background:'#4D81F4',
                            border:"0"}}
                        >
                                <span style={{fontWeight:"bold"}}>
                                    Confirm Scores
                                </span>
                        </Button>
                    :
                        !emptyHoles ?
                            <Button 
                                onClick={()=>onSubmitScorecard()} 
                                style={
                                    emptyHoles ? {background:'#BFBFBF',
                                    border:"0"} : {background:'#4D81F4',
                                    border:"0"}
                                }
                            >
                                <span style={{fontWeight:"bold"}}>
                                    Confirm Scores
                                </span>
                            </Button>
                        :
                            <Button 
                                onClick={()=>onAlertScoresMissing()} 
                                style={{
                                    background:'#BFBFBF',
                                    color:"#2D083F",
                                    border:"0",
                                }}
                            >
                                <span style={{fontWeight:"bold"}}>
                                    Confirm Scores
                                </span>
                            </Button>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default ScorecardSummaryFooterNavigation