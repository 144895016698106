import React, {useState} from 'react'
import { ListGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
// import { useAuth } from '../util/auth';
import { createGroupScorecardPlayers } from '../util/db';
// import { createHoleScore } from '../util/db'; 
// import { getLayoutHoles } from '../util/db';
import { useRouter } from '../util/router';
import ScorecardPlayerSelectRow from './ScorecardPlayerSelectRow';

// function createScorecard (layout_id,event_entry_id,uid){
//   getLayoutHoles(layout_id).then((data) => {
//     data.map(hole => (
//         createHoleScore({
//           layout_holes_id: hole.layout_holes_id,
//           event_entries_id: event_entry_id,
//           scorekeeper_id: uid
//       })
//     ))
//   })
// }

//async function to create scorecard
// async function createScorecard (layout_id,event_entry_id,uid){
//   const data = await getLayoutHoles(layout_id)
//   const unresolvedPromises = data.map(hole => (
//     createHoleScore({
//       layout_holes_id: hole.layout_holes_id,
//       event_entries_id: event_entry_id,
//     })
//   ))
//   const results = await Promise.all(unresolvedPromises)
//   return results
// }


function ScorecardAddPlayerSelect (props){

  const [selectedPlayers, setSelectedPlayers] = useState([])
  const [confirmDisabled, setConfirmDisabled] = useState(false)
  // const eventId = props.eventId
  // const auth = useAuth()
  // const uid = auth.user ? auth.user.uid : undefined;
  const router = useRouter();

  const handleConfirmPlayers = () => {

    if(selectedPlayers.length !== 0){
      setConfirmDisabled(true)

      // const dataGroupScorecard = {
      //     event_id: eventId,
      //     card_setup_user: uid,
      // }

      //create group scorecard in group_scorecard table and return id
    //   createGroupScorecard(dataGroupScorecard).then((groupScorecardId) => {

        //loop through selected players, and add to group_scorecard_players table
        selectedPlayers.forEach((player) => {
          const dataGroupScorecardPlayers = {
              group_scorecard_id: props.scorecardId,
              event_entries_id: player,
          }

          //create group scorecard players
          createGroupScorecardPlayers(dataGroupScorecardPlayers)

          //create holescores for each player
          // getEventDivisionsIdFromEventEntries(player).then((eventDivisionsId) => {
          //     // console.log(eventDivisionsId.event_divisions_id,'eventDivisionsId')
          //     getLayoutIdFromEventDivisions(eventDivisionsId.event_divisions_id).then((layoutId) => {

          //         //create scorecard, then redirect to scorecard
          //         createScorecard(layoutId.layout_id,player,uid).then(() => {
          //             //redirect to scorecard
          //             router.push(`/scorecard?scorecard=${props.scorecardId}`)
          //         })
          //     })
          // })

          //redirect to scorecard
          router.push(`/scorecard?scorecard=${props.scorecardId}`)
        })
    //   })
    }else{
      alert('Please select players to create a scorecard.')
    }
  }

  // const handleAddClick = (id) =>  {
  //   if(!selectedPlayers.includes(id)){
  //     //copy current selected players
  //     let newSelectedPlayers = selectedPlayers

  //     //add id to newSelectedPlayers
  //     newSelectedPlayers.push(id)

  //     //set selectedPlayers to newSelectedPlayers
  //     setSelectedPlayers(newSelectedPlayers)
  //   }
  // }
  
  // const handleRemoveClick = (id) =>  {
  //   //copy current selected players
  //   let newSelectedPlayers = selectedPlayers

  //   //remove id from newSelectedPlayers
  //   newSelectedPlayers.splice(newSelectedPlayers.indexOf(id),1)

  //   //set selectedPlayers to newSelectedPlayers
  //   setSelectedPlayers(newSelectedPlayers)
  // }

  const availablePlayers = props.items
  // console.log(availablePlayers,'availablePlayers')

  return(        
    <div>
      <ListGroup as="ul">
        {availablePlayers.map((item,index) => (   
            <ScorecardPlayerSelectRow key={index} item={item} selectedPlayers={selectedPlayers} setSelectedPlayers={setSelectedPlayers}/>
        ))}
      </ListGroup>
      <br />
      <div className='text-center'>
        <Button disabled={confirmDisabled} className='w-100' onClick={handleConfirmPlayers}>
          Add to Scorecard
        </Button>
      </div>
    </div>
  )
}

export default ScorecardAddPlayerSelect