import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useAuth } from "./../util/auth";
import Badge from 'react-bootstrap/Badge'
import logo from "../img/kpleagues_badge_blue3.png";
import { getIsTd } from "../util/db";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState, useEffect } from "react";

// eslint-disable-next-line
import styles from "./NavbarCustom.css";

function NavbarCustom(props) {
  const auth = useAuth();
  const uid = auth.user ? auth.user.uid : null;

  const [isPlayerTd, setIsPlayerTd] = React.useState(false);
  //if uid is not null, check if user is a TD
  const isTd = uid ? getIsTd(uid) : null;

  //useeffect to update isplayertd when uid changes
  React.useEffect(() => {
    if(uid){
      isTd.then((result) => {
        setIsPlayerTd(result.is_td)
      })
    }
  }, [isTd, uid])

  const [isAdmin, setIsAdmin] = useState(false)

  //useeffect to reset is admin on auth.user change
  useEffect(() => {
      if(auth.user && auth.user.user_access_level_id === 3){
          setIsAdmin(true)
      }else{
          setIsAdmin(false)
      }
  }, [auth.user])
  
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" style={{backgroundImage: 'linear-gradient(#2D083F, #5B107E)'}}>
      <Container>
        <Navbar.Brand href="/" style={{color:'#90B1F8',fontWeight:'bold',fontSize:'30px'}}>
            <img
              className="d-inline-block align-top mr-2"
              src={logo}
              alt="KPLeagues"
              height="50"
            />
            DGscores
          <sup>
            <Badge pill style={{color:'#C9CBE7'}}>beta</Badge>
          </sup>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{backgroundColor:'#90B1F8'}} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" style={{background:"#FFFFFF"}}>
            {/* <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            
          </Nav>
          {auth.user && (
            <>
              <LinkContainer to="/events">
              <Nav.Link>
                <span style={{color:"#FFFFFF",fontWeight:'bold'}}>
                  Events
                </span>
              </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/profile">
                <Nav.Link>
                  <span style={{color:"#FFFFFF",fontWeight:'bold'}}>Profile</span>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/settings/general">
                <Nav.Link>
                  <span style={{color:"#FFFFFF",fontWeight:'bold'}}>
                    Settings
                  </span>
                </Nav.Link>
              </LinkContainer>

              {/* Navdropdown text color should be white and bold */}
              {isPlayerTd && (
                <div style={{color:"#FFFFFF",fontWeight:'bold'}}>
                  <NavDropdown title="LD Tools" id="collapsible-nav-dropdown" className="dropdown-link">
                    <LinkContainer to="/event_create">
                      <NavDropdown.Item className="dropdown-item">Create Event</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/event_view">
                      <NavDropdown.Item className="dropdown-item">View Events</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                </div>
              )}

              {isAdmin && (
                <LinkContainer to="/admin">
                <Nav.Link>
                  <span style={{color:"#FFFFFF",fontWeight:'bold'}}>Admin</span>
                </Nav.Link>
              </LinkContainer>
              )}


              <LinkContainer to="/auth/signout">
              <Nav.Link                      
                onClick={(e) => {
                e.preventDefault();
                auth.signout();
              }}>
                <span style={{color:"#FFFFFF",fontWeight:'bold'}}>
                  Sign Out
                </span>
              </Nav.Link>
              </LinkContainer>
            </>
          )}
          {!auth.user && (
            <>
              <LinkContainer to="/events">
              <Nav.Link>
                <span style={{color:"#FFFFFF",fontWeight:'bold'}}>
                  Events
                </span>
              </Nav.Link>
              </LinkContainer>
              <Nav.Link href="/auth/signin">
                <span style={{color:"#FFFFFF",fontWeight:'bold'}}>
                  Sign In
                </span>
              </Nav.Link>
            </>
          )
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
    // <Navbar style={{backgroundImage: 'linear-gradient(#2D083F, #5B107E)'}} expand={props.expand}>
    //   <Container fluid>
    //     <LinkContainer to="/">
    //       <Navbar.Brand>
    //         {/* <img
    //           className="d-inline-block align-top"
    //           src={img}
    //           alt="Logo"
    //           height="100"
    //         /> */}
    //         <Row>
    //           <h1 style={{color:'#90B1F8',fontWeight:'bold'}} className="ml-3">
    //             DGscores
    //           </h1>
    //           <sup>
    //             <Badge pill style={{color:'#C9CBE7'}}>beta</Badge>
    //           </sup>
    //         </Row>
    //       </Navbar.Brand>
    //     </LinkContainer>
    //     <Navbar.Toggle aria-controls="navbar-nav" className="border-0" style={{backgroundColor:'#90B1F8'}} />
    //     <Navbar.Collapse id="navbar-nav" className="justify-content-end" style={{background:"#FFFFFF"}}>
    //       <Nav>
    //         {/* <NavDropdown 
    //           id="dropdown"  
    //           title={
    //               <span className="text-white">Navigation</span>
    //           } 
    //           alignRight={true}
    //         > */}

    //           <LinkContainer to="/events">
    //             <NavDropdown.Item active={false}>Events</NavDropdown.Item>
    //           </LinkContainer>

    //           {auth.user && (
    //             <>
    //               <LinkContainer to="/profile">
    //                 <NavDropdown.Item active={false}>Profile</NavDropdown.Item>
    //               </LinkContainer>
    //               <LinkContainer to="/settings/general">
    //                 <NavDropdown.Item active={false}>Settings</NavDropdown.Item>
    //               </LinkContainer>
    //               <Dropdown.Divider />
    //               <LinkContainer to="/auth/signout">
    //                 <NavDropdown.Item
    //                   active={false}
    //                   onClick={(e) => {
    //                     e.preventDefault();
    //                     auth.signout();
    //                   }}
    //                 >
    //                   Sign Out
    //                 </NavDropdown.Item>
    //               </LinkContainer>
    //             </>
    //           )}
    //           {!auth.user && (
    //             <>
    //               <Dropdown.Divider />
    //               <LinkContainer to="/auth/signin">
    //                 <NavDropdown.Item active={false}>
    //                   Sign in
    //                 </NavDropdown.Item>
    //               </LinkContainer>
    //             </>
    //           )}
    //         {/* </NavDropdown> */}
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
    // <Navbar style={{backgroundImage: 'linear-gradient(#2D083F, #5B107E)'}} collapseOnSelect expand="lg" className="bg-body-tertiary">
      
    //     <Navbar.Brand style={{color:'#90B1F8',fontWeight:'bold'}} href="/">
    //       DGscores
    //       <sup>
    //         <Badge pill style={{color:'#C9CBE7'}}>beta</Badge>
    //       </sup>
    //     </Navbar.Brand>
    //     <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{backgroundColor:'#90B1F8'}} />
    //     <Navbar.Collapse id="responsive-navbar-nav">
    //       <Nav className="me-auto">
    //       {auth.user && (
    //         <>
    //                <LinkContainer to="/profile" style={{color:"#FFFFFF"}}>
    //                  <Nav.Item>Profile</Nav.Item>
    //                </LinkContainer>
    //         <Nav.Link href="/settings/general" style={{color:"#FFFFFF"}}>Settings</Nav.Link>
    //         <Nav.Link href="/events" style={{color:"#FFFFFF"}}>Events</Nav.Link>
    //         <HrCustom />
    //         <Nav.Link href="/auth/signout" style={{color:"#FFFFFF"}}>Sign Out</Nav.Link>
    //         </>
    //       )}
    //       {!auth.user && (
    //         <>
    //         <Nav.Link href="/auth/signin" style={{color:"#FFFFFF"}}>Sign In</Nav.Link>
    //         </>
    //       )}
    //       </Nav>
    //     </Navbar.Collapse>
      
    // </Navbar>

    // <Navbar style={{backgroundImage: 'linear-gradient(#2D083F, #5B107E)'}} expand={props.expand}>
    //   <Container fluid>
    //     <LinkContainer to="/">
    //       <Navbar.Brand>
    //         <Row>
    //           <h1 style={{color:'#90B1F8',fontWeight:'bold'}} className="ml-3">
    //             DGscores
    //           </h1>
    //           <sup>
    //             <Badge pill style={{color:'#C9CBE7'}}>beta</Badge>
    //           </sup>
    //         </Row>
    //       </Navbar.Brand>
    //     </LinkContainer>
    //     <Navbar.Toggle aria-controls="navbar-nav" className="border-0" style={{backgroundColor:'#90B1F8'}} />
    //     <Navbar.Collapse id="navbar-nav" className="justify-content-end" style={{background:"#FFFFFF"}}>
    //       <Nav>
    //         {/* <NavDropdown 
    //           id="dropdown"  
    //           title={
    //               <span className="text-white">Navigation</span>
    //           } 
    //           alignRight={true}
    //         > */}

    //           <LinkContainer to="/events">
    //             <NavDropdown.Item active={false}>Events</NavDropdown.Item>
    //           </LinkContainer>

    //           {auth.user && (
    //             <>
    //               <LinkContainer to="/profile">
    //                 <NavDropdown.Item active={false}>Profile</NavDropdown.Item>
    //               </LinkContainer>
    //               <LinkContainer to="/settings/general">
    //                 <NavDropdown.Item active={false}>Settings</NavDropdown.Item>
    //               </LinkContainer>
    //               <Dropdown.Divider />
    //               <LinkContainer to="/auth/signout">
    //                 <NavDropdown.Item
    //                   active={false}
    //                   onClick={(e) => {
    //                     e.preventDefault();
    //                     auth.signout();
    //                   }}
    //                 >
    //                   Sign Out
    //                 </NavDropdown.Item>
    //               </LinkContainer>
    //             </>
    //           )}
    //           {!auth.user && (
    //             <>
    //               <Dropdown.Divider />
    //               <LinkContainer to="/auth/signin">
    //                 <NavDropdown.Item active={false}>
    //                   Sign in
    //                 </NavDropdown.Item>
    //               </LinkContainer>
    //             </>
    //           )}
    //         {/* </NavDropdown> */}
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
  );
}

export default NavbarCustom;