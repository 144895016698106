import React from 'react'
import { hideForumPost } from '../util/db'
import { unHideForumPost } from '../util/db'
import { useState } from 'react'

function EventChatDisplayPostsRow({post}) {

    const [isHidden, setIsHidden] = useState(post.is_hidden)

    const handlePostHide = (e) => {
        hideForumPost(post.id,post.event_id)
        setIsHidden(true)
    }

    const handlePostUnHide = (e) => {
        unHideForumPost(post.id,post.event_id)
        setIsHidden(false)
    }

    return (
        <>
        {isHidden ? (
            <tr style={{background:"#FF0000"}}>
                <td className='text-center'>
                    {post.users_public.name}
                </td>
                <td className='text-center'>
                    {new Date(post.created_at).toLocaleString()}
                </td>
                <td className='text-center'>
                    {post.post}
                </td>
                <td className="text-center" onClick={handlePostUnHide}>
                    🗑️
                </td>
            </tr>
        ):
            <tr>
                <td className='text-center'>
                    {post.users_public.name}
                </td>
                <td className='text-center'>
                    {new Date(post.created_at).toLocaleString()}
                </td>
                <td className='text-center'>
                    {post.post}
                </td>
                <td className="text-center" onClick={handlePostHide}>
                    🗑️
                </td>
            </tr>
        }

        </>
    )
}

export default EventChatDisplayPostsRow