import React from 'react'
import ScorecardRow from './ScorecardRow'
import Table from 'react-bootstrap/Table'
import ScorecardNavbarTop from './ScorecardNavbarTop'

const ScorecardTable = ({showTotals, players, currentHole, onUpdateHoleScore,isComplete,onLeftNav,onRightNav,showSummary,toggleSummary}) => {

  return (
    <>
      <ScorecardNavbarTop currentHole={currentHole} onLeftNav={onLeftNav} onRightNav={onRightNav} showSummary={showSummary} toggleSummary={toggleSummary}/>
      
      <Table size='sm' borderless style={{tableLayout:'fixed',wordWrap:'break-word'}} >
        <tbody>
          
          <tr style={{backgroundColor:'#2D083F', color:'#ffffff',textAlign:'center'}}>    
              <th>Player</th>
              <th style={{width:"80px"}}></th>
              <th style={{width:"80px"}}>Score</th>
              <th style={{width:"80px"}}></th>
          </tr>
          {players.map((player, index) =>  
            <ScorecardRow showTotals={showTotals} isComplete={isComplete} key={index} player={player} currentHole={currentHole} onUpdateHoleScore={onUpdateHoleScore}/>
          )}
        </tbody>
      </Table>
    </>
  )
}

export default ScorecardTable