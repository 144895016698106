import React from 'react'
import { Link } from '../util/router';
import { ListGroup } from 'react-bootstrap';

function ScorecardSettings({ eventId,scorecardId}) {
  return (    
    <ListGroup>
      <ListGroup.Item>
        <Link to={`/scorecard/addplayer?event=${eventId}&scorecard=${scorecardId}`}>Add Player</Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link to={`/scorecard/removeplayer?event=${eventId}&scorecard=${scorecardId}`}>Remove Player</Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link to={`/scorecard/dnfplayer?event=${eventId}&scorecard=${scorecardId}`}>DNF Player</Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link to={`/scorecard/showhidetotals?event=${eventId}&scorecard=${scorecardId}`}>Show/Hide Totals</Link>
      </ListGroup.Item>
    </ListGroup>
  )
}

export default ScorecardSettings