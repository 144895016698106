function ScorecardRowPlayerTd({playerName, showTotals, total, toggleHoleInfo}) {
    
  return (
    <td style={{verticalAlign:'middle', padding:'20px 0', fontSize:'20px',fontWeight:'bold',fontStyle:'italic',color:'#2D083F'}} className='pl-1'>
      <div>
        {playerName} 
        {showTotals && <span style={{fontSize:'12px',fontWeight:'normal',color:'#2D083F'}}> ({total})</span>}
        <span onClick={toggleHoleInfo} className="mb-2 ml-2">
          ℹ️
        </span>
      </div>
    </td>
  );
}

export default ScorecardRowPlayerTd;