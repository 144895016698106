import React from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {  useRouter } from '../util/router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const ScorecardBeginCheckIn = ({eventId,canCheckIn,isIncompleteCards,isCheckedInAlready}) => {
    const router = useRouter();

    return (
        <Row>
          <Col>
            <Card className="mb-2" border="primary" style={{border:'none'}}>
                <Card.Header
                    as="h5"
                    // className="d-flex justify-content-between align-items-center"
                    style={{background:"#2D083F", text:"white"}}
                >
                </Card.Header>
                
                <Card.Body className="justify-content-center align-items-center" style={{background:"#FFFFFF", fontWeight:'bold'}}>
                    <Row
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Col>
                            Are you checking in?
                        </Col>
                        <Col>
                            <Button
                                style={{background:'#4D81F4',fontWeight:'bold',border:"0"}}
                                onClick={() => router.push(`/register?id=${eventId}`)}
                                disabled={!canCheckIn}
                            >
                                Click Here
                            </Button> 
                        </Col>
                        <Col>
                            {isIncompleteCards && 
                                <span style={{color:'red', fontStyle:'italic', fontSize:'10px'}}>
                                    Please submit any incomplete scorecards before checking in.
                                </span>
                            }{isCheckedInAlready &&
                                <span style={{color:'red', fontStyle:'italic', fontSize:'10px'}}>
                                    You have already checked in.
                                </span>
                            }
                        </Col>

                    </Row>
                    <div style={{padding:'10px 0'}}></div>
                    <Row
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Col>
                            <span style={{color:'#C877ED', fontStyle:'italic'}}>or</span> Are you checking in another player?
                        </Col>
                        <Col>
                            <Button
                                style={{background:'#4D81F4',fontWeight:'bold',border:"0"}}
                                onClick={() => router.push(`/register_guest?id=${eventId}`)}
                            >
                                Click Here
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>      
                <Card.Footer className="d-flex" style={{background:"#2D083F"}}>

                </Card.Footer>  
            </Card>
            </Col>
        </Row>  
    );
};

export default ScorecardBeginCheckIn;
