import React from 'react'

const ScorecardSummaryRow = ({player}) => {
    

    const holes = player.event_entries.hole_scores
    // sort holes by hole_order
    holes.sort((a,b) => (a.layout_holes.hole_order > b.layout_holes.hole_order) ? 1 : ((b.layout_holes.hole_order > a.layout_holes.hole_order) ? -1 : 0))

    //calculate this players total score
    let total = 0
    let isEmptyHole = 'false'
    let numFives = 0
    player.event_entries.hole_scores.forEach((score, index) => {
        total += score.score
        if(score.score === null){
            isEmptyHole = 'true'
        }
        if(score.score === 5){
            numFives++
        }
    }) 

    return (
        <tr>
            <td className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"#2D083F", position:'sticky',left:0,background:"#FFFFFF",fontWeight:'bold',fontStyle:'italic' }}>
                {player.event_entries.users_public.name}
            </td>
            {holes.map((score, index) => 
                <React.Fragment key={index}>
                    {score.score == null ?
                        <td key={index} className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"#2D083F",background:'#C9CBE7' }}>-</td>
                    :
                        <td key={index} className="text-center align-middle" style={{fontWeight: "bold", width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"#2d083f",background:'#FFFFFF' }}>
                            {score.score === 5 ?
                                    <span 
                                        style={{
                                            // color: 
                                            //     (index+1)%4===1 ? "#FF0000" : //1
                                            //     (index+1)%4===2 ? "#000000" : //2
                                            //     (index+1)%4===3 ? "#0000FF" : //3
                                            //     (index+1)%4===0 ? "000000" : //4
                                            //     "#000000",
                                            // background: 
                                            //     (index+1)%4===1 ? "#FFFFFF" : 
                                            //     (index+1)%4===2 ? "#FFFFFF" : 
                                            //     (index+1)%4===3 ? "#FFFFFF" : 
                                            //     (index+1)%4===0 ? "000000" :
                                            //     "#FFFFFF"
                                            // color: (index+1)%4===1 ? "#FF0000" : (index+1)%4===2 ? "#000000" : (index+1)%4===3 ? "#0000FF" : (index+1)%4===0 ? "#000000" : "#FFFFFF",
                                            // backgroundColor: (index+1)%4===1 ? "#FFFFFF" : (index+1)%4===2 ? "#FFFFFF" : (index+1)%4===3 ? "#FFFFFF" : (index+1)%4===0 ? "#FFFF00" : "#FFFF00",
                                            color: "#2d083f",
                                            backgroundColor: "#ffffff"
                                        }}
                                    >
                                        &#9734;
                                    </span> 
                                : 
                                    <span style={{fontWeight:'bold',fontStyle:'italic' }}>
                                        {score.score}
                                    </span>
                            }
                        </td>
                    }
                </React.Fragment>
            )}

            {player.event_entries.is_dnf 
                ?
                    isEmptyHole === 'true'
                        ?
                        <td className="align-middle text-center" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"black",background:'#C9CBE7',fontWeight:'bold',fontStyle:'italic'  }}>DNF</td>
                        :
                        <td className="align-middle text-center" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"black",fontWeight:'bold',fontStyle:'italic'  }}>DNF</td>
                :
                    isEmptyHole === 'true'
                        ?
                        <td className="align-middle text-center" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"black",background:'#C9CBE7',fontWeight:'bold',fontStyle:'italic'  }}>{total}</td>
                        :
                        <td className="align-middle text-center" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"black",fontWeight:'bold',fontStyle:'italic'  }}>{total}</td>
            }
            {isEmptyHole === 'true'
              ?
              <td className="align-middle text-center" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"black",background:'#C9CBE7',fontWeight:'bold',fontStyle:'italic'  }}>{numFives}</td>
              :
              <td className="align-middle text-center" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"black",fontWeight:'bold',fontStyle:'italic'  }}>{numFives}</td>
            }
        </tr>
    )
}

export default ScorecardSummaryRow