import React, { useEffect } from 'react'
import { useAuth } from './../util/auth';
import { useState } from 'react';
import SectionHeader from './../components/SectionHeader';
import AdminPageContent from './../components/AdminPageContent';

function AdminPage() {
    const auth = useAuth();
    const [isAdmin, setIsAdmin] = useState(false)

    //useeffect to reset is admin on auth.user change
    useEffect(() => {
        if(auth.user && auth.user.user_access_level_id === 3){
            setIsAdmin(true)
        }else{
            setIsAdmin(false)
        }
    }, [auth.user])

    return (
        <>
            <SectionHeader
                title={'Admin'}
                // subtitle={props.subtitle}
                size={1}
                spaced={true}
                className="text-center mt-3 mb-1"
            />
            {isAdmin ? (
                <AdminPageContent auth={auth} isAdmin={isAdmin} />
            ) : (
                <p>
                    Please log in as an authorized user to continue
                </p>
            )}
        </>
    )
}

export default AdminPage