import React from 'react'
// import AdminPageViewLdsTable from './AdminPageViewLdsTable';
// import HrCustom from './HrCustom';
import SectionHeader from './SectionHeader';
import AdminPageApproveLdsTable from './AdminPageApproveLdsTable';

function AdminPageApproveLds() {

  return (
    <>
      {/* <SectionHeader
        title="Current LDs"
        subtitle="Current LDs in the system"
        className="text-center mt-4"
      />
      <AdminPageViewLdsTable/>
      <HrCustom/> */}
      <SectionHeader
        title="LD Approval"
        subtitle="Approve or deny LD applications"
        className="text-center mt-4"
      />
      <AdminPageApproveLdsTable/>
    </>
  )
}

export default AdminPageApproveLds