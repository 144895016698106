import React from 'react';
import { usePlayersByEvent } from '../util/db';
import { Container } from 'react-bootstrap';
import HrCustom from './HrCustom';
import { useState } from 'react';
import { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { deleteEvent } from '../util/db';


function EventDeletePanel({eventId,title}) {
    //look up all players in db
    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = usePlayersByEvent(eventId);

    const [allowDelete, setAllowDelete] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleDelete = () => {



        //delete event
        deleteEvent(eventId).then(
            console.log('event deleted')
        )
        // handleCloseModal();

        //redirect to admin page
        window.location.href = '/admin'
    }

    useEffect(() => {
    if(itemsStatus !== "loading" && items && items.length === 0){
        setAllowDelete(true)
    }
    }, [itemsStatus, items]);

    return (
        <Container>
            <p className='mt-3'>To remove event, please <a href={`/event_manage?event=${eventId}`}>remove</a> all players from event.</p>

            {allowDelete && (
                <button className='btn btn-danger' onClick={handleShowModal}>Delete Event</button>
            )}

            <HrCustom />

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
                    <Button variant="danger" onClick={handleDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default EventDeletePanel;

