import React, { useEffect } from 'react'
import { useAuth } from './../util/auth';
import { useState } from 'react';
import SectionHeader from './../components/SectionHeader';
import AdminPageEditLayoutForm from '../components/AdminPageEditLayoutForm';
import { useRouter } from "./../util/router.js";
import { useLayoutHolesLayoutEdit } from "../util/db";
import { Link } from './../util/router.js';

function EditLayoutPage() {
    const auth = useAuth();
    const [isAdmin, setIsAdmin] = useState(false)

    const router = useRouter()
    const { id } = router.query

    const {
        data: layoutHoles,
        status: layoutHolesStatus,
        // error: layoutHolesError,
      } = useLayoutHolesLayoutEdit(id)
    
      const [layoutName, setLayoutName] = React.useState('')
    
      useEffect(() => {
          if(layoutHoles && layoutHoles.length > 0){
              setLayoutName(layoutHoles[0].layout.layout_name)
          }
      }, [layoutHoles])

    //useeffect to reset is admin on auth.user change
    useEffect(() => {
        if(auth.user && auth.user.user_access_level_id === 3){
            setIsAdmin(true)
        }else{
            setIsAdmin(false)
        }
    }, [auth.user])

    return (
        <>
            <SectionHeader
                title={'Edit Layout'}
                subtitle={layoutName}
                size={1}
                spaced={true}
                className="text-center mt-3 mb-1"
            />
            {isAdmin ? (
                <AdminPageEditLayoutForm id={id} layoutHoles={layoutHoles} layoutHolesStatus={layoutHolesStatus}/>
            ) : (
                <p>
                    Please log in as an authorized user to continue
                </p>
            )}
            {isAdmin && (
                <Link to={`/admin`} className="mb-3 text-center">
                    Back to Admin
                </Link>
            )}
        </>
    )
}

export default EditLayoutPage