import React from 'react'
import SectionHeader from './SectionHeader';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { createCourse } from '../util/db';

function AdminPageCourseAdd({auth}) {
  const [courseName, setCourseName] = React.useState('')

  

  const handleFormCourseNameChange = (e) => {
    setCourseName(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('submitting')

    // if there is no course name entred, alert
    // else, add course to db
    if (courseName === '') {
      alert('Please enter a course name')
    } else {
      // add course to db

      const courseToAdd = {
        course_name: courseName,
        added_by: auth.user.id
      }

      createCourse(courseToAdd).then((response) => {
          alert('Course added')
          setCourseName('')
      })
    }
    
  }

  return (
    <>
    <SectionHeader
      title={'Add Course'}
      // subtitle={props.subtitle}
      size={1}
      spaced={true}
      className="text-center mt-3 mb-1"
    />
    <Form>
      <Form.Group>
        <Form.Label>Course Name</Form.Label>
        <Form.Control type="text" onChange={handleFormCourseNameChange} required placeholder="Enter course name" value={courseName} />
      </Form.Group>

      <Button variant="primary" type="submit" onClick={handleSubmit}>
        Add Course
      </Button>      
    </Form>
    </>
  )
}

export default AdminPageCourseAdd