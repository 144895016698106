import React from 'react';
import { useForumPostsNotHidden } from '../util/db';
import EventChatPost from './EventChatPost';
import Row from 'react-bootstrap/Row';


function EventChatDisplayPosts(props) {

    //get posts from db hook
    const {
        data: items,
        // status: itemsStatus,
        // error: itemsError,
    } = useForumPostsNotHidden(props.eventId);

    return (
        <div className='mb-3'>
            {items && items.length > 0 && (
                items.map((item, index) => (
                    <Row className='mt-3' key={index}>
                        <EventChatPost key={index} item={item} />
                    </Row>
                ))
            )}
        </div>
    );
}

export default EventChatDisplayPosts