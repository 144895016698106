import React from 'react';
import { Link } from '../util/router';
import { useAuth } from '../util/auth';
import EventChatPostCreate from '../components/EventChatPostCreate';
import Card from 'react-bootstrap/Card';
import EventChatDisplayPosts from '../components/EventChatDisplayPosts';


function EventChat ({eventId,uid}){
    const auth = useAuth();
    return (
        <>
            <EventChatDisplayPosts eventId={eventId} />
            {auth.user ? (
                <EventChatPostCreate eventId={eventId} uid={uid} />
            ) : (
                <Card>
                   <Card.Header style={{background:"#2D083F", color:"white"}}>
                        Post
                    </Card.Header>
                    <Card.Body className="text-center" style={{background:'#FFFFFF'}}>
                        <p>Please sign in to post</p>
                        <Link to="/auth/signin" className="btn btn-primary">
                            Sign In
                        </Link>
                    </Card.Body>
                </Card>
            )}
      </>
    );
}
export default EventChat