import { useState } from "react";
import { Form,Button } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { updateLayoutHole } from "../util/db";

function AdminPageEditLayoutRow({item, holesData, index}) {

    const [isEditted, setIsEditted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [holeSelected,setHoleSelected] = useState(item.hole_id)
    const thisLayoutHolesId=item.layout_holes_id

    // changehole handler
    const onSubmit = (e) => {
        e.preventDefault()
        setIsSubmitting(true);
        updateLayoutHole(thisLayoutHolesId,holeSelected)
        setIsSubmitted(true);
        setIsSubmitting(false);
    }

    const onSelectHole = (e) =>{
        e.preventDefault()
        setHoleSelected(e.target.value);
        setIsEditted(true);
        setIsSubmitted(false);
    }

    return (
        <tr key={index}>
            
            <td>{item.hole_order}</td>
            <td>{item.hole_id}</td>
            <td>{item.holes.hole_name}</td>
            <td>{item.holes.hole_length}</td>
            <td>{item.holes.hole_description}</td>
            <td>{item.holes.hole_par}</td>
            <td>
                <Form.Control as="select" style={{color:"#2D083F"}} required value={holeSelected} onChange={onSelectHole}>
                    {holesData?.map((hole, index2) => (
                        <option key={index2} value={hole.hole_id}>{hole.hole_name}({hole.hole_id})</option>
                    ))}
                </Form.Control>
            </td>
            <td>
            <Form onSubmit={onSubmit}>
                <Button type="submit" disabled={!isEditted || isSubmitting}>
                    {isSubmitted ? '✔' : isSubmitting ? <Spinner animation="border" /> : 'Update'}
                </Button>
                </Form>               
            </td>
            
      </tr>
    );
}
export default AdminPageEditLayoutRow;
