import { usePlayerNameByEntryId, useScorecard } from "../util/db";
import Spinner from "react-bootstrap/Spinner";
import FormAlert from "./FormAlert";
import { useState } from 'react';
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

function DisplayScorecard(props) {

    const eventEntryId = props.eventEntryId
    const [totalScore, setTotalScore] = useState(0);

    //get scorecard for this evententryid
    const {
        data: holesScores,
        status: holesScoresStatus,
        error: holesScoresError,
    } = useScorecard(eventEntryId);

    // get player name
    const {  
        data: playerName, 
        status: playerNameStatus, 
        // error: playerNameError, 
    } = usePlayerNameByEntryId(eventEntryId);

    let playersName = "";
    if(playerNameStatus !== "loading" && playerName !== undefined && playerName.length > 0) {
        playersName=playerName[0].users_public.name
    }


    if(holesScoresStatus !== "loading" && holesScores !== undefined && holesScores.length > 0) {
        const result = holesScores.reduce((total, currentValue) => total = total + currentValue.score,0);
        if(totalScore !== result){
          setTotalScore(result)
        }
      }

    return (
        <>
            {holesScoresStatus === "loading" && (
                <div className="py-5 px-3 align-self-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {holesScoresStatus === "error" && (
                <div className="py-5 px-3 align-self-center">
                    <FormAlert type="error" message={holesScoresError.message} />
                </div>
            )}

            {holesScoresStatus === "success" && holesScores && holesScores.length > 0 && (

                <>
            
                    {/* table for hole scores */}    
                    <Table responsive style={{ width: "100%", tableLayout: "auto", border: "2px solid #2D083F"}}>
                        <thead style={{background:"#2D083F", text:"white"}}>
                            <tr>
                                <th className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F" }}></th>
                                {holesScores.map((item,index) => (
                                    <th 
                                        className="align-middle text-center"
                                        key={item.hole_scores_id} 
                                        style={{ 
                                            width: "auto", 
                                            tableLayout: "auto", 
                                            border: "2px solid #2D083F",
                                            cursor:'pointer',
                                            color:(index+1)%4===1 ? "#FFFFFF" : (index+1)%4===2 ? "#2D083F" : (index+1)%4===3 ? "#FFFFFF" : "#2D083F" , 
                                            background: (index+1)%4===1 ? "#FF0000" : (index+1)%4===2 ? "#FFFFFF" : (index+1)%4===3 ? "#0000FF" : "#FFFF00"
                                        }}
                                    >
                                        {item.layout_holes.hole_order}
                                    </th>
                                ))}
                                <th className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"white" }}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"#2D083F", position:'sticky',left:0,background:"#FFFFFF",fontWeight:'bold',fontStyle:'italic' }}>
                                    {playersName}
                                </td>
                                    {holesScores.map((item) => (
                                        <td key={item.hole_scores_id} className="align-middle text-center" style={{ width: "auto", tableLayout: "auto", border: "2px solid #2D083F", color:"#2D083F",fontWeight:'bold' }}>
                                            {item.score === 5 
                                                ? 
                                                    <Link style={{color:'#2d083f'}} to={`/holescoreedits?hole=${item.hole_scores_id}&event_entries_id=${props.eventEntryId}&scorecard=${props.scorecard}&event=${props.event}`}>
                                                        &#9734; 
                                                    </Link>
                                                : 
                                                    <Link style={{color:'#2d083f',fontStyle:'italic'}} to={`/holescoreedits?hole=${item.hole_scores_id}&event_entries_id=${props.eventEntryId}&scorecard=${props.scorecard}&event=${props.event}`}>
                                                        {item.score}
                                                    </Link>
                                            }
                                        </td>
                                    ))}
                                    <td className="align-middle text-center" style={{ width: "auto", tableLayout: "auto", border: "2px solid #2D083F", fontWeight:'bold',fontStyle:'italic'}}>{totalScore}</td>
                            </tr>
                        </tbody>
                    </Table>
                
                <br />
                </>
            )}
            {holesScoresStatus === "success" && holesScores.length === 0 && (
                <p>No scorecard available</p>
            )}
        </>
    );
}

export default DisplayScorecard;