import { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useEventVenue, updateEventVenue, useCourses } from '../util/db';

function EventManageVenueChange({eventId}) {
    const [eventVenue, setEventVenue] = useState();
    
    const [venues, setVenues] = useState([]);
    const [isEditted, setIsEditted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { data: items, status: itemsStatus } = useEventVenue(eventId);
    const { data: courses, status: coursesStatus } = useCourses();

    useEffect(() => {
        if (itemsStatus !== "loading" && items && items.length > 0) {
            setEventVenue(
               items[0].courses.id
            );
        }
    }, [items, itemsStatus]);

    useEffect(() => {
        if (coursesStatus !== "loading" && courses && courses.length > 0) {
            setVenues(courses);
        }
    }, [courses, coursesStatus]);

    const onEventVenueChange = (e) => {
        setEventVenue(e.target.value);
        setIsEditted(true);
        setIsSubmitted(false);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        await updateEventVenue(eventId, eventVenue);
        setIsSubmitted(true);
        setIsSubmitting(false);
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="name">
                <Row>
                    <Col>
                        <Form.Label style={{color:"#2D083F"}}>Event Venue</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control as="select" style={{color:"#2D083F"}} required value={eventVenue} onChange={onEventVenueChange}>
                            {venues.map((venue, index) => (
                                <option key={index} value={venue.id}>{venue.course_name}</option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col>
                        <Button type="submit" disabled={!isEditted || isSubmitting}>
                            {isSubmitted ? '✔' : isSubmitting ? <Spinner animation="border" /> : 'Update'}
                        </Button>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    );
}

export default EventManageVenueChange;