import { Table } from "react-bootstrap";
import { useHoles } from "../util/db";
import React from 'react';
import AdminPageEditLayoutRow from "./AdminPageEditLayoutRow";

function AdminPageEditLayoutForm({id,layoutHoles,layoutHolesStatus}) {

  const {
    data: holesData,
    // status: holesStatusData,
    // error: holesError,
  }= useHoles()

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Order</th>
          <th>Hole Id</th>
          <th>Hole Name</th>
          <th>Length</th>
          <th>Description</th>
          <th>Par</th>
          <th>Change Hole</th>
          <th>Change</th>
        </tr>
      </thead>
      <tbody>
        {layoutHolesStatus !== "loading" && layoutHoles && layoutHoles.length > 0 && (
          layoutHoles.map((item, index) => (
            <AdminPageEditLayoutRow key={index} item={item} holesData={holesData}/>
          ))
        )}
      </tbody>
    </Table>
  );
}

export default AdminPageEditLayoutForm;