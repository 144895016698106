import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import FormField from "./FormField";
import { useAuth } from "./../util/auth";
import { useUsersPublicName } from "../util/db";
import {updateUserName} from "../util/db";

function SettingsName(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const {
    data: usersName = [],
    // status: usersNameStatus,
    // error: usersNameError,
    } = useUsersPublicName(auth.user.id);
  
  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    //await update user name
    updateUserName(auth.user.id, data.name).then(() => {
        // Set success status
        props.onStatus({
            type: "success",
            message: "Your profile has been updated",
        });
        }
    )
    .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
            props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
            });
        } else {
            // Set error status
            props.onStatus({
            type: "error",
            message: error.message,
            });
        }
        }
    )
    .finally(() => {
        // Hide pending indicator
        setPending(false);
        }
    );

    // Update user profile
    // updateUserName(auth.user.id, {name:data.name}).then(() => {
    //   // Set success status
    //   props.onStatus({
    //     type: "success",
    //     message: "Your profile has been updated",
    //   });
    // })
    // .catch((error) => {
    //     if (error.code === "auth/requires-recent-login") {
    //         props.onStatus({
    //         type: "requires-recent-login",
    //         // Resubmit after reauth flow
    //         callback: () => onSubmit(data),
    //         });
    //     } else {
    //         // Set error status
    //         props.onStatus({
    //         type: "error",
    //         message: error.message,
    //         });
    //     }
    //     })
    //     .finally(() => {
    //     // Hide pending indicator
    //     setPending(false);
    //     }
    // );
    

    // return auth
    //   .updateProfile(data)
    //   .then(() => {
    //     // Set success status
    //     props.onStatus({
    //       type: "success",
    //       message: "Your profile has been updated",
    //     });
    //   })
    //   .catch((error) => {
    //     if (error.code === "auth/requires-recent-login") {
    //       props.onStatus({
    //         type: "requires-recent-login",
    //         // Resubmit after reauth flow
    //         callback: () => onSubmit(data),
    //       });
    //     } else {
    //       // Set error status
    //       props.onStatus({
    //         type: "error",
    //         message: error.message,
    //       });
    //     }
    //   })
    //   .finally(() => {
    //     // Hide pending indicator
    //     setPending(false);
    //   });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formName">
        <FormField
          name="name"
          type="text"
          label="Name"
          defaultValue={usersName.name || ""}
        //   placeholder="Name"
          error={errors.name}
          size="lg"
          inputRef={register({
            required: "Please enter your name",
          })}
        />
      </Form.Group>

      <Button style={{background:'#4D81F4'}} type="submit" size="lg" disabled={pending}>
        <span>Save</span>

        {pending && (
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden={true}
            className="ml-2 align-baseline"
          >
            <span className="sr-only">Sending...</span>
          </Spinner>
        )}
      </Button>
    </Form>
  );
}

export default SettingsName;
