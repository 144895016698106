import React from "react";
import Meta from "./../components/Meta";
import { useRouter } from "../util/router";
import { useUsersName } from "../util/db";
import Container from "react-bootstrap/Container";
import SectionHeader from "../components/SectionHeader";
import PlayersEvents from "../components/PlayersEvents";
import DashboardStats from "../components/DashboardStats";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import DashboardScorecards from "../components/DashboardScorecards";

function PlayerPage(props) {

    const router = useRouter()
    const uid = router.query.id
    
    function checkForName(){
        if(usersName.length === 0){
          return false
        }else{
          return true
        }
      }

    //for users name
    const {
        data: usersName = [],
        // status: usersNameStatus,
        // error: usersNameError,
    } = useUsersName(uid);

  return (
    <>
      <Meta title="Player" />

      <Container>
        <SectionHeader
          size={1}
          spaced={true}
          className="text-center mt-4"
          title={checkForName() ? usersName[0].name : ""}
        />
        <Tabs defaultActiveKey="events" id="uncontrolled-tab-example">
          <Tab eventKey="stats" title="Stats">
            <DashboardStats uid={uid}/>
          </Tab>
          <Tab eventKey="events" title="Events">
            <PlayersEvents uid={uid}/>
          </Tab>
          <Tab eventKey="scorecards" title="Scorecards">
            <DashboardScorecards uid={uid} />
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}

export default PlayerPage;
