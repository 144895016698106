import React from 'react';
import Card from 'react-bootstrap/Card';


function ScorecardEventCompleteCard(){    

    return (
        <Card className="mb-2" border="primary">
                <Card.Header
                    as="h5"
                    // className="d-flex justify-content-between align-items-center"
                    style={{background:"#2D083F", text:"white"}}
                >
                    <Card.Title style={{color:"#ffffff"}}>
                        Event Complete
                    </Card.Title>
                </Card.Header>
                
                <Card.Body className="justify-content-center align-items-center" style={{background:"#FFFFFF"}}>
                    <p className=" text-center">
                        This event is complete.  Thank you for playing!
                    </p>
                </Card.Body>      
            </Card>
    )

}

export default ScorecardEventCompleteCard;