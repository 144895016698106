import AdminPageDisplayHolesRowName from "./AdminPageDisplayHolesRowName"

function AdminPageDisplayHolesRow({item}){
    return(
        <tr>
            <td>{item.hole_id}</td>
            <AdminPageDisplayHolesRowName item={item} />
            <td>{item.hole_length}</td>
            <td>{item.hole_description}</td>
            <td>{item.hole_par}</td>
            <td>{item.hole_setup_notes}</td>
       </tr>
    )
}

export default AdminPageDisplayHolesRow