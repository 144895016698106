import React from "react";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { useAuth } from "./../util/auth";
import DashboardStats from "./DashboardStats";
import DashboardAccountInfo from "./DashboardAccountInfo";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import DashboardScorecards from "./DashboardScorecards";

function DashboardSection(props) {
  const auth = useAuth();
  const uid = auth.user ? auth.user.uid : null;

  return (
    <Container>
      <SectionHeader
        title={props.title}
        subtitle={auth.user.name}
        size={1}
        spaced={true}
        className="text-center mt-4"
      />
      <Tabs defaultActiveKey="account" id="uncontrolled-tab-example">
        <Tab eventKey="account" title="Account">
          <DashboardAccountInfo auth={auth} />
        </Tab>
        <Tab eventKey="stats" title="Stats">
          <DashboardStats uid={uid} />
        </Tab>
        <Tab eventKey="events" title="Events">
          <DashboardItems uid={uid} />
        </Tab>
        <Tab eventKey="scorecards" title="Scorecards">
          <DashboardScorecards uid={uid} />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default DashboardSection;
