import React, { useState,useEffect } from 'react'
import { Card, Spinner, Table } from 'react-bootstrap'
import EventManageCardConfirmDeleteModal from './EventManageCardConfirmDeleteModal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from '../util/router'
import { updateScorecardIsSubmitted } from '../util/db'
import { updateScorecardNotSubmitted } from '../util/db'
import { updateScorecardIsHandled } from '../util/db'
import { updateScorecardIsNotHandled } from '../util/db'
import ViewScorecardHeaderRow from './ViewScorecardHeaderRow';
import ViewScorecardRow from './ViewScorecardRow'

function EventManageCardsFullCard({card,uid, eventId, isShowAll}) {
    const [isCardComplete,setIsCardComplete] = useState(card.is_complete)
    const [isHandled,setIsHandled] = useState(card.is_handled)
    const [isHandling,setIsHandling] = useState(false)
    const numHoles = 20

    //useeffect to refresh component when card is complete
    useEffect(() => {
        setIsCardComplete(card.is_complete)
    }, [card.is_complete])
    
    function onLockCard() {
        updateScorecardIsSubmitted(card.id, card.event_id, uid)
        //set card iscomplete to true
        setIsCardComplete(true)
    }

    function onUnlockCard() {
        updateScorecardNotSubmitted(card.id, card.event_id, uid)
        //set card iscomplete to false
        setIsCardComplete(false)
    }

    function onHandleCard() {

        // if card is not complete, do not allow to be handled
        if (!isCardComplete) {
            alert('Please lock the card before collapsing')
        } else {
            setIsHandling(true)
            setIsHandled(true)

            //update in db
            updateScorecardIsHandled(card.id, card.event_id).then(() => {
                setIsHandling(false)
            }).catch((error) => {
                console.error('Error updating scorecard is_handled', error)
                setIsHandled(false)
                setIsHandling(false)
            })
        }
    }

    function onUnhandleCard() {
        setIsHandling(true)
        setIsHandled(false)

        //update in db
        updateScorecardIsNotHandled(card.id, card.event_id).then(() => {
            setIsHandling(false)
        }).catch((error) => {
            console.error('Error updating scorecard is_handled', error)
            setIsHandled(true)
            setIsHandling(false)
        })
    }

    return (
        <Card className='m-1 mb-5' style={{background:"#2d083F"}}>
            <Card.Header style={{backgroundColor:'#2d083F',color:"#FFFFFF"}} className='pb-0'>
                <Card.Title style={{fontWeight:'bold'}}>
                    Card {card.id}
                </Card.Title>
                <Card.Subtitle className='mb-2 text-muted'>
                    {card.group_scorecard_players.length} Players
                </Card.Subtitle>

                {!isHandled && (
                    !card.is_complete ? 
                        <div className='mb-2 text-muted' style={{background:"#ffffff",height:"30px",borderRadius:"10%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            🥏 Active
                        </div> : 
                        <div className='mb-2 text-muted' style={{background:"#DFF0D8",height:"30px",borderRadius:"10%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            ✔️ Verified
                        </div>
                )}
                
                {!isShowAll && (
                    <div style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: '#FFFFFF',
                        borderRadius: '10%',
                        height: '30px',
                        width: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {isHandling 
                                ? 
                                    <Spinner animation="border" variant='primary' />
                                :
                                    isHandled 
                                        ? 
                                            <div style={{cursor:'pointer'}} onClick={onUnhandleCard}>📗</div> 
                                        : 
                                            <div style={{cursor:'pointer'}} onClick={onHandleCard}>📖</div>
                        }
                    </div>
                )}
            </Card.Header>
            <Card.Body className='text-center px-0 py-0'>
                {(!isHandled || isShowAll) && (
                    <Table size='sm' className='px-0 py-0'>
                        <tbody className='px-0 py-0' style={{background: isCardComplete ? '#DFF0D8' : '#FFFFFF'}}>
                            <tr>
                                <td>
                                    Created:
                                </td>
                                <td>
                                    {new Date(card.created_at).toLocaleTimeString()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Finished: 
                                </td>
                                <td>
                                    {card.completed_at ? new Date(card.completed_at).toLocaleTimeString() : 'In Progress'}
                                </td>   
                            </tr>
                            {card.completed_at && (
                                <tr>
                                    <td>
                                        Time to Complete:
                                    </td>
                                    <td>
                                        {Math.floor((new Date(card.completed_at) - new Date(card.created_at))/60000)} minutes
                                    </td>
                                </tr>   
                            )}
                        </tbody>
                    </Table>
                )}
                {(!isHandled || isShowAll) && (
                    <Table size='sm' style={{background: isCardComplete ? '#DFF0D8' : "#FFFFFF"}} className='mx-0 my-0 w-100' responsive>
                        <thead style={{background:"#2D083F", text:"white"}}>
                            <ViewScorecardHeaderRow numHoles={numHoles}/>
                        </thead>
                        <tbody>
                        {card.group_scorecard_players.map((player, index) => (
                            <ViewScorecardRow player={player} key={index} isCardComplete={isCardComplete} event={eventId}/>    
                        ))}
                        </tbody>
                    </Table>
                )}
            </Card.Body>

            {!isHandled && (
            <Card.Footer style={{backgroundColor:'#2d083F'}}>
                <Row>
                    <Col>
                        {/* //if card is complete show lock else show unlock */}
                        {isCardComplete ? (
                            <div style={{cursor:'pointer'}} onClick={onUnlockCard}>🔐</div>
                        ):(
                            <div style={{cursor:'pointer'}} onClick={onLockCard}>🗝️</div>
                        )}
                    </Col>
                    <Col style={{display:'flex', justifyContent:'center'}}>
                        <Link to={`scorecard?scorecard=${card.id}`}>
                            👀
                        </Link>
                    </Col>
                    <Col>
                        <EventManageCardConfirmDeleteModal 
                            buttonText={'🗑️'}
                            heading={'Delete Card'}
                            bodyText={'Are you sure you want to delete this card?'}
                            cancelText={'Cancel'}
                            confirmText={'Delete'}
                            card={card}
                        />
                    </Col>
                </Row>
            </Card.Footer>
            )}
        </Card>
    )
}

export default EventManageCardsFullCard