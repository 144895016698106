import SectionHeader from "./SectionHeader";
import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import { createHole } from "../util/db";


function AdminPageCreateHole({auth}) {

    const [holeName, setHoleName] = useState('');
    const [holeLength, setHoleLength] = useState(0);
    const [holeDescription, setHoleDescription] = useState('');
    const [holeSetupNotes, setHoleSetupNotes] = useState('');
    const [holePar, setHolePar] = useState(0);

    const handleSubmit = (e) => {

        e.preventDefault();

        const data = {
            hole_name: holeName,
            hole_length: holeLength,
            hole_description: holeDescription,
            hole_setup_notes: holeSetupNotes,
            hole_par: holePar,
            added_by: auth.user.id
        }

        createHole(data).then((response) => {
            alert('Hole added');
            setHoleName('');
            setHoleLength(0);
            setHoleDescription('');
            setHoleSetupNotes('');
            setHolePar(0);
        }).catch((error) => {
            alert('Error adding hole');
        })
        
    }

    return(
        <>
            <SectionHeader
                title={'Add Hole'}
                size={1}
                spaced={true}
                className="text-center mt-3 mb-1"
            />
            <Form>
                <Form.Group>
                    <Form.Label>Hole Name</Form.Label>
                    <Form.Control type="text" onChange={(e) => setHoleName(e.target.value)} required placeholder="Enter hole name" value={holeName} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Hole Length</Form.Label>
                    <Form.Control type="number" onChange={(e) => setHoleLength(e.target.value)} placeholder="Enter hole length" value={holeLength} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Hole Description</Form.Label>
                    <Form.Control type="text" onChange={(e) => setHoleDescription(e.target.value)} placeholder="Enter hole description" value={holeDescription} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Hole Setup Notes</Form.Label>
                    <Form.Control type="text" onChange={(e) => setHoleSetupNotes(e.target.value)} placeholder="Enter hole setup notes" value={holeSetupNotes} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Hole Par</Form.Label>
                    <Form.Control type="number" onChange={(e) => setHolePar(e.target.value)} placeholder="Enter hole par" value={holePar} />
                </Form.Group>
            
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Add Hole
                </Button>      
            </Form>
        </>
    )
}

export default AdminPageCreateHole;