import React, { useEffect } from 'react'
import { useAuth } from '../util/auth';
import { useState } from 'react';
import SectionHeader from '../components/SectionHeader';
import { useEventName } from '../util/db';
import EventDeletePanel from '../components/EventDeletePanel';

function EventDeletePage() {
    const auth = useAuth();
    const [isAdmin, setIsAdmin] = useState(false)

    //get event id from url
    const urlParams = new URLSearchParams(window.location.search);
    const eventId = urlParams.get('event');

    //get event name from db
    const {
        data: eventName,
        // status: eventNameStatus,
        // error: eventNameError,
    } = useEventName(eventId);

    //if eventName[0].name exists, set title to eventName[0].name, else set title to 'Event'
    let title = eventName ? eventName[0].name : 'Event'

    //useeffect to reset is admin on auth.user change
    useEffect(() => {
        if(auth.user && auth.user.user_access_level_id === 3){
            setIsAdmin(true)
        }else{
            setIsAdmin(false)
        }
    }, [auth.user])

    return (
        <>
            <SectionHeader
                title={ title}
                // subtitle={props.subtitle}
                size={1}
                spaced={true}
                className="text-center mt-3 mb-1"
            />
            {isAdmin ? (
                <EventDeletePanel eventId={eventId} title={title} />
            ) : (
                <p>
                    Please log in as an authorized user to continue
                </p>
            )}
        </>
    )
}

export default EventDeletePage