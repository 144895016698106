import { Link } from "react-router-dom/cjs/react-router-dom.min";

function EventsTableRowPast ({event}){
    
    return(
        <tr>
            <td className='text-center'>
            {new Date(event.date).toLocaleDateString()}
            </td>

            <td className='text-start'>
                <Link to={`/event?event=${event.id}`}>
                {event.name}
                </Link>
            </td>
            <td className='text-center'>
            {event.games.name}
            </td>

            <td>
            {event.courses ? event.courses.course_name : "" }
            </td>


        </tr>
    )
}

export default EventsTableRowPast;