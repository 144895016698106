import React from 'react';

const HrCustom = ({width}) => {
    return (

            width 
                ? 
                    <hr style={{ width: width, backgroundColor:'#000000'}} /> 
               : 
                    <hr style={{ width: '90%', backgroundColor:'#000000'}} />

    );
};

export default HrCustom;
