import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { usePlayersByEventDivisionNotGolf } from "../util/db"
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";

function EventDivisionPlayersNotGolf (props) {

    const {
        data: items,
        status: itemsStatus,
        // error: itemsError,
    } = usePlayersByEventDivisionNotGolf(props.eventId,props.event_divisions_id);

    const itemsAreEmpty = !items || items.length === 0;

    return (
        <>
            {(itemsStatus === "loading" || itemsAreEmpty) && (
                <>
                    {itemsStatus === "loading" && (
                        <div className="py-5 px-3 align-self-center">
                            <Spinner animation="border" variant="primary">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    )}

                    {/* {itemsStatus !== "loading" && itemsAreEmpty && (
                        <>No players yet</>
                    )} */}
                </>
            )}

            {itemsStatus !== "loading" && items && (
                <Table striped hover className="mb-0">
                    <thead style={props.headerStyle}>
                        <tr>
                            <td style={props.titleStyle}><h4 style={{fontWeight:'bold'}}>{props.divisionName}</h4></td>
                            <td style={props.titleStyle}><h4 style={{fontWeight:'bold'}}>Score</h4></td>
                            {/* if event is complete, display points/payouts */}
                            {/* {props.isEventComplete && 
                            <td style={{color:"#ffffff"}}>Payout</td>}
                            {props.isEventComplete && 
                            <td style={{color:"#ffffff"}}>Points</td>} */}
                        </tr>
                    </thead>
                    <tbody style={{background:"#ffffff"}}>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <Link to={`/player?id=${item.user_id}`}>{item.users_public.name}</Link>
                                    {}
                                </td>
                                { 
                                    item.is_dnf === true 
                                    ?
                                        <td>DNF</td>
                                    :
                                        <td >
                                            {
                                                !item.is_dropped 
                                                ?
                                                    <>
                                                    {item.score}
                                                    {item.score !== null 
                                                        ?
                                                            item.group_scorecard_players.length !== 0 &&
                                                                <Link to={`/display_scorecard?evententry=${item.id}&event=${props.eventId}&scorecard=${item.group_scorecard_players[0].group_scorecard_id}`}>
                                                                    📇
                                                                </Link>
                                                        :
                                                            <>
                                                                {item.group_scorecard_players.length !== 0
                                                                    ?
                                                                        '🥏'
                                                                    :
                                                                        null
                                                                }
                                                            </>
                                                    }
                                                    </>
                                                : 
                                                <s>{item.score}</s>
                                            }
                                        </td>
                                }                            
                                {/* {props.isEventComplete  ?
                                        !item.payouts || item.payouts.length === 0 ?
                                            <td></td>//event is complete. no payout
                                            :
                                            <td>${item.payouts[0].payout_amount?item.payouts[0].payout_amount:<></>}</td> 
                                            //event is complete. payout
                                        :
                                        null//event is not complete
                                }                         

                                {props.isEventComplete ? 
                                    <td>{item.points}</td> 
                                    :null} */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            {itemsStatus !== "loading" && items && items.length === 0 && (
                <Container className="pt-3">
                    <>No players yet</>
                </Container>
            )}
        </>
    )
}

export default EventDivisionPlayersNotGolf