import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { createForumPost } from '../util/db';

const EventChatPostCreate = ({eventId,uid}) => {
    // Component logic goes here
    const onClickPost = (e) => {
        e.preventDefault();

        // create post data object
        const postData = {
            event_id: eventId,
            user: uid,
            post: postText,
        };

        // create post in db
        createForumPost(postData);

        // clear post
        setPostText('');
    };

    const [postText, setPostText] = useState('');

    return (
        // JSX code goes here
        <Card>
            <Card.Header style={{background:"#2D083F", color:"white"}}>
                Post In Chat
            </Card.Header>
            {/* card body with centered content */}
            <Card.Body className="text-center" style={{background:'#FFFFFF'}}>
                <Form>
                    <Form.Group controlId="posttext">
                        <Form.Control as="textarea" rows={2} value={postText} onChange={(e)=>setPostText(e.target.value)} />
                    </Form.Group>
                </Form>
                <Button style={{color:'#FFFFFF', background:'#4D81F4'}} disabled={!postText} onClick={onClickPost}>
                    Post
                </Button>
            </Card.Body>
            <Card.Footer style={{background:"#2D083F", color:"white"}}>
            </Card.Footer>
        </Card>
    );
};

export default EventChatPostCreate;
