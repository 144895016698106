import React from "react";

import { Link } from "./../util/router.js";

function EventTdSection(props) {
  const eventId = props.eventId;
  return (
    <div className="text-right">
      
      <Link to={`/event_manage?event=${eventId}`}>
        Manage Event⚙️
      </Link>
    </div>
  );
}

export default EventTdSection;
