import { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useEventName, updateEventName } from '../util/db';

function EventManageNameChange({eventId}) {
    const [eventName, setEventName] = useState('');
    const [isEditted, setIsEditted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { data: items, status: itemsStatus } = useEventName(eventId);

    useEffect(() => {
        if (itemsStatus !== "loading" && items && items.length > 0) {
            setEventName(items[0].name);
        }
    }, [items, itemsStatus]);

    const onEventNameChange = (e) => {
        setEventName(e.target.value);
        setIsEditted(true);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        await updateEventName(eventId, eventName);
        setIsSubmitted(true);
        setIsSubmitting(false);
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="name">
                <Row>
                    <Col>
                        <Form.Label style={{color:"#2D083F"}}>Event Name</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control type="text" style={{color:"#2D083F"}} required value={eventName} onChange={onEventNameChange} />
                    </Col>
                    <Col>
                        <Button type="submit" disabled={!isEditted || isSubmitting}>
                            {isSubmitted ? '✔' : isSubmitting ? <Spinner animation="border" /> : 'Update'}
                        </Button>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    );
}

export default EventManageNameChange;