import React from 'react'
import Button from 'react-bootstrap/Button'

function ScorecardScoreButton({backgroundColor,textColor,text}) {
    
    const style = {
        borderRadius: '15px',
        padding: '30px',
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        color: textColor,
        fontSize: '30px',
        fontWeight: 'bold',
    }

    return (
        <Button style={style}>
            {text}
        </Button>
    )
}

export default ScorecardScoreButton