import { Link } from "react-router-dom";

function ScorecardRow({item,eventId}) {
    //sort this row of scores
    const thisRowScoresSorted = item.hole_scores.sort((a,b) => {
        return a.layout_holes.hole_order - b.layout_holes.hole_order
    })

    const thisRowsScoreTotal = thisRowScoresSorted.reduce((a,b) => {
        return a + b.score
    },0)

    //get number of 5s
    const numFives = thisRowScoresSorted.filter((score) => {
        return score.score === 5
    }).length

    return (
        <tr>
            {/* <td style={{ width: "auto", tableLayout: "auto", border: "2px solid #2D083F" }}> */}
            <td className="align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"#2D083F", position:'sticky', left:0,background:"#FFFFFF",fontWeight:'bold',fontStyle:'italic'}}>
                <Link to={`/player?id=${item.user_id}`}>
                {item.users_public.name}    
                </Link>
            </td>
            
            {thisRowScoresSorted.map((hole,holeIndex) => (
                    // <td key={holeIndex} className="align-bottom" style={{ width: "auto", tableLayout: "auto", border: "2px solid #2D083F" }}>
                
                hole.score === 4 
                    ?
                        (<td key={holeIndex} className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"#2d083f", background:'#C9CBE7' }}>                        
                            <Link to={`/holescoreedits?hole=${hole.hole_scores_id}&event=${eventId}`} style={{color:"black"}}>{hole.score}</Link>
                        </td>)
                    :
                    hole.score === 5
                        ?
                            (<td key={holeIndex} className="text-center align-middle" 
                            
                            style={{ 
                                width: "30px", 
                                tableLayout: "auto", 
                                border: "2px solid #2D083F", 
                                }}
                            >                        
                                <Link 
                                    to={`/holescoreedits?hole=${hole.hole_scores_id}&event=${eventId}`} 
                                    style={{
                                        // color: (holeIndex+1)%4===1 ? "#FF0000" : (holeIndex+1)%4===2 ? "#000000" : (holeIndex+1)%4===3 ? "#0000FF" : (holeIndex+1)%4===0 ? "#000000" : "#FFFFFF",
                                        // backgroundColor: (holeIndex+1)%4===1 ? "#FFFFFF" : (holeIndex+1)%4===2 ? "#FFFFFF" : (holeIndex+1)%4===3 ? "#FFFFFF" : (holeIndex+1)%4===0 ? "#FFFF00" : "#FFFF00",
                                        color: "#2d083f",
                                        backgroundColor: "#ffffff",
                                        fontWeight: "bold"
                                    }}
                                >
                                    &#9734;
                                </Link>
                            </td>)    
                        :
                            (<td key={holeIndex} className="text-center align-middle" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"#2D083F",fontWeight:'bold',fontStyle:'italic' }}>                        
                                <Link to={`/holescoreedits?hole=${hole.hole_scores_id}&event=${eventId}`} style={{color:"#2D083F"}}>{hole.score}</Link>
                            </td>)
            ))}

            
            
            {/* <td className="align-bottom" style={{ width: "auto", tableLayout: "auto", border: "2px solid #2D083F" }}> */}
            <td className="align-middle text-center" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"#2D083F",fontWeight:'bold',fontStyle:'italic' }}>
                {item.is_dnf ? "DNF" : thisRowsScoreTotal}
                
            </td>            
            
            <td className="align-middle text-center" style={{ width: "30px", tableLayout: "auto", border: "2px solid #2D083F", color:"#2D083F",fontWeight:'bold',fontStyle:'italic' }}>
                {numFives}  
            </td>
        </tr>
    )
}

export default ScorecardRow;