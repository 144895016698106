import React, { useState } from 'react';
import { ListGroupItem, Button, Modal } from 'react-bootstrap';
//from database file db.js
import { deleteEventEntryId,deleteHoleScores } from '../util/db';
import { useQueryClient } from 'react-query';

function ScorecardPlayerSelectRow({item, selectedPlayers, setSelectedPlayers, auth, eventId}) {

  const [isSelected, setIsSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const queryClient = useQueryClient();

  const handleSelect = (e) => {
    if(!selectedPlayers.includes(item.id)){
      setSelectedPlayers([...selectedPlayers, item.id]);
      setIsSelected(true);
    }
  }

  const handleUnSelect = (e) => {
    const newSelectedPlayers = selectedPlayers.filter((player) => player !== item.id);
    setSelectedPlayers(newSelectedPlayers);
    setIsSelected(false);
  }

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleDelete = () => {
    deleteHoleScores(item.id).then(() => {
      deleteEventEntryId(item.id).then(() => {
        queryClient.invalidateQueries(["playersnotplayingyetkey", eventId]);
        handleCloseModal();
      })
    })
  }

  return (
    <>
      <ListGroupItem key={item.user_id} value={item.id} onClick={isSelected ? handleUnSelect : handleSelect} style={isSelected ? { backgroundColor: 'lightgrey' } : {}}>
        <div className="d-flex justify-content-between align-items-center">
          {item.users_public.name}
          {auth && auth.user && item.user_id === auth.user.id && 
          <>
            <Button className="border-0" variant="link" onClick={()=>handleShowModal()}>
              🗑️
            </Button>
          </>
          
          }
        </div>
      </ListGroupItem>
        
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to remove?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ScorecardPlayerSelectRow