import { Button, Col, Row } from 'react-bootstrap';
// import ScorecardSummaryTablePreview from './ScorecardSummaryTablePreview';
import { useRouter } from '../util/router';
import ScorecardSummaryTable5x4 from './ScorecardSummaryTable5x4';



function ScorecardBeginCardInProgress({item}) {
    const router = useRouter();

    return(
        <div className="text-center">
            <Row>
                <Col>
                    {/* <ScorecardSummaryTablePreview scorecard={item.group_scorecard_players[0].group_scorecard_id} eventEntryId={item.id} /> */}
                    <ScorecardSummaryTable5x4 scorecard={item.group_scorecard_players[0].group_scorecard_id} eventEntryId={item.id} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        style={{background:'#4D81F4',fontWeight:'bold',border:"0"}}
                        onClick={() => router.push(`/scorecard?scorecard=${item.group_scorecard_players[0].group_scorecard_id}`)}
                        className="mt-2"
                    >
                    Resume Scorecard
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default ScorecardBeginCardInProgress;