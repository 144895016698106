import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUsersName } from '../util/db';


function AdminPageEventsRow({ item }) {
    // useUsersName from db to look up td name
    const {
        data: tdName,
        status: tdNameStatus,
        // error: tdNameError,
      } = useUsersName(item.td_id);

      const [tdNameDisplay, setTdNameDisplay] = React.useState("");

      useEffect(() => {
        if (tdNameStatus === "success") {
            setTdNameDisplay(tdName[0].name)
        }
      }, [tdNameStatus, tdName]);


  return (
    <tr>
        <td><Link to={`/event?event=${item.id}`}>{item.name}</Link></td>
        <td><Link to={`/player?id=${item.td_id}`}>{tdNameDisplay}</Link></td>
        <td><Link to={`/event_manage?event=${item.id}`}>
            ⚙️
        </Link></td>
        <td>{item.date}</td>
        <td>
            {item.is_event_complete ? 'Complete' : 'Open'}
            {!item.is_event_complete && 
                <Link to={`/event_delete?event=${item.id}`}>
                    🗑️
                </Link>
            }
        </td>
    </tr>
  );
}

export default AdminPageEventsRow;