import React, { useEffect } from 'react'
import Form from 'react-bootstrap/Form'

// function EventCreateChooseCoursesRow({selectedDivision,selectedDivisions,setSelectedDivisions,layouts}){
function EventCreateChooseCoursesRow({courses,selectedVenue,setSelectedVenue,setSelectedVenueName,selectedVenueName}){

    const handleVenueChange = (e) => {
        setSelectedVenue(e.target.value)
        let courseName = e.target.options[e.target.selectedIndex].text
        setSelectedVenueName(courseName) 
    }

    // if selectedVenueName="", set selectedVenueName to the name of the courses with the id=selectedVenue
    useEffect(() => {
        if(selectedVenueName===""){
            let courseName = courses.find(course => course.id === selectedVenue).course_name
            setSelectedVenueName(courseName)
        }
    },[selectedVenueName,selectedVenue,courses,setSelectedVenueName])

    return(
        <tr>
            <td className='text-center'>
                <Form.Control as='select'
                    onChange={handleVenueChange}
                    value={selectedVenue}
                >
                    {courses.map((course, index) => (
                        <option 
                            key={index} 
                            value={course.id} 
                            name={course.course_name} 
                            id={course.id}
                        >
                            {course.course_name}
                        </option>               
                    ))}
                </Form.Control>    
            </td>
        </tr>
    )
}

export default EventCreateChooseCoursesRow